import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { getRequest } from "../../../axios/axios.js";
import SearchIcon from '@mui/icons-material/Search';
import { toast } from "react-toastify";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/DemoContainer.js";
import { useMobilePicker } from "@mui/x-date-pickers/internals/hooks/useMobilePicker/useMobilePicker.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


// this code is created for both devices PC and Mobile separately
// if you want to change anything, change both side   

const filterNames = [
  "Order Status",
  "Customer Phone",
  "Customer First Name",
  "Customer Email",
  "Customer Last Name",
  // 'Customer Address'
];

const JobsFilters = ({ onSearch, countryId, countryName, refreshList, disabledProp = false, setStoredQuery, setOffset, offset, isFromServiceOrderReportPage = false }) => {
  // const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedFilter, setFilterNames] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState(undefined);
  const [serviceOrderNumber, setServiceOrderNumber] = React.useState();
  const [serviceOrderStatus, setServiceOrderStatus] = React.useState();
  const [customerPhone, setCustomerPhone] = React.useState();
  const [customerFirstName, setCustomerFirstName] = React.useState();
  const [customerLastName, setCustomerLastName] = React.useState();
  const [customerEmail, setCustomerEmail] = React.useState();
  const [serviceOrderSubStatus, setServiceOrderSubStatus] = React.useState();

  const [subStatus, setsubStatus] = React.useState([])
  const [status, setSTatus] = React.useState([])

  const [fromDate, setFromDate] = React.useState('')
  const [toDate, setToDate] = React.useState("")
  const [postCode, setPostCode] = React.useState();
  const [plusCode, setPlusCode] = React.useState();
  const [address, setAddress] = React.useState("");
  const [disabledSubStatus, setDisabledSubStatus] = React.useState(true)
  const [firstAddressLine, setFirstAddressLine] = React.useState("");
  const [isPostCodeExit, setIsPostCodeExit] = React.useState(false)
  const [isAddressSelected, setIsAddressSelected] = React.useState(false)

  const [city, setCity] = React.useState("");
  const [stateCode, setSateCode] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [country, setCoutry] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("")
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [coordinates, setCorrdinates] = React.useState({
    lat: null,
    lang: null
  })
  const [query, setQuery] = React.useState();

  const getWindowSize = () => {
    const { innerWidth } = window;
    return innerWidth;
  }


  const [windowSize, setWindowSize] = React.useState(getWindowSize())

  const currentDate = new Date()

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  })
  React.useEffect(() => {

    if (windowSize > 600) {
      setOpenDialog(false)
    }

  }, [windowSize])

  const open = Boolean(anchorEl);

  const handleToggle = (event) => {
    // setShowFilter(false)
    // setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    setAnchorEl(null)
    // setOpen(false);
  };
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);


  const applyFilter = () => {
    // setOpen(false)
    setAnchorEl(null)
    setShowFilter(true)
  }

  const resetAllFilters = () => {

    setPostCode()
    setServiceOrderStatus('')
    setCustomerFirstName('')
    setCustomerLastName('')
    setCustomerEmail('')
    setCustomerPhone('')
    setServiceOrderNumber()
    setAddress("")
    refreshList()
    setServiceOrderSubStatus("")
    setFromDate("")
    setToDate("")
    setFilterNames([])
    setFirstAddressLine('')
    setStoredQuery("")
    setCustomerAddress('')
    setOpenDialog(false)

  }
  const resetFilter = (name) => {
    switch (name) {
      case 'Service Status':
        setSelectedStatus(undefined)
        break;
      default:
        break;
    }
  }
  React.useEffect(() => {
    buildQuery()
    if (offset !== 0) {
      setOffset(0)
    }

  }, [fromDate, toDate, serviceOrderSubStatus, customerEmail, customerFirstName, customerLastName, customerPhone, serviceOrderStatus, serviceOrderNumber, selectedStatus, postCode, address, customerAddress])

  const buildQuery = () => {

    let queryString = ''


    if (serviceOrderStatus) {
      queryString += `&serviceOrderStatus=${serviceOrderStatus}`
    }
    if (serviceOrderSubStatus) {
      queryString += `&serviceOrderSubStatus=${serviceOrderSubStatus}`
    }
    if (customerEmail) {
      queryString += `&customerEmail=${customerEmail}`
    }
    if (customerPhone) {
      queryString += `&customerPhone=${customerPhone}`
    }

    if (customerFirstName) {
      queryString += `&customerFirstName=${customerFirstName}`
    }
    if (customerLastName) {
      queryString += `&customerLastName=${customerLastName}`
    }
    if (serviceOrderNumber) {
      queryString += `&serviceOrderNumber=${serviceOrderNumber}`
    }

    if (fromDate) {
      queryString += `&fromDate=${fromDate}`
    }
    if (toDate) {
      queryString += `&toDate=${toDate}`
    }
    if (customerAddress) {
      queryString += `&customerAddress=${customerAddress}`
    }
    if (postCode) {
      queryString += `&postCode=${postCode}`
    }
    setQuery(queryString)
    setStoredQuery(queryString)
  }

  const search = () => {
    onSearch(query)
    setOpenDialog(false)
  }


  const parseAddressComponents = (addressComponents) => {
    addressComponents.forEach((component) => {
      if (component.types.includes('locality')) {
        setCity(component.long_name);
      } else if (component.types.includes('administrative_area_level_1')) {
        setSateCode(component.short_name);
      } else if (component.types.includes('country')) {
        setCoutry(component.short_name);
      } else if (component.types.includes('postal_code')) {
        setZipCode(component.long_name);
      }
    });
  };

  const handleChange = (name) => {
    if (selectedFilter.includes(name)) {
      resetFilter(name)
      const selectItems = selectedFilter.filter(function (item) {
        return item !== name;
      });
      setFilterNames([...selectItems]);
    } else {
      setFilterNames([...selectedFilter, name]);
    }
  };


  const handleSTatus = (event) => {
    setDisabledSubStatus(false)
    setServiceOrderStatus(event.target.value)
  }
  const handleSubSTatus = (event) => {
    setServiceOrderSubStatus(event.target.value)
  }
  const getStatusList = () => {

    getRequest(`/api/getOrderStatusList`, "sdIndustry")
      .then(response => {

        if (response.data.isSuccess) {
          setSTatus(response?.data?.items)
        }
      })
      .catch(err => {
        toast.error(err)
      });
  }
  const getsubStatusList = () => {
    getRequest(`/api/getOrderSubStatusList?orderStatusName=${serviceOrderStatus}`, "sdIndustry")
      .then(response => {
        if (response.data.isSuccess) {
          setsubStatus(response?.data?.items)
        }
      })
      .catch(err => {
        toast.error(err)
      });
  }
  React.useEffect(() => {
    getStatusList()
  }, [])

  React.useEffect(() => {
    if (serviceOrderStatus) {
      getsubStatusList()
    }
  }, [serviceOrderStatus])
  const GridStyle = {

    "&.MuiGrid-root": {
      maxWidth: "12.9%"
    },
  }

  const handleFromDateSelect = (date) => {
    if (date && dayjs(date).isValid()) {
      setFromDate(date);
    } else {
      console.error('Invalid From Date:', date);
    }
  };
  const handleToDateSelect = (date) => {
    if (date && dayjs(date).isValid()) {
      setToDate(date);
    } else {
      console.error('Invalid From Date:', date);
    }
  }


  // for mobile screen
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleToggleMobile = () => {
    setOpenDialog(true)
  }
  const handleToggleDialogClose = () => {
    setOpenDialog(!openDialog)
  }

  React.useEffect(() => {

  })

  return (
    <>

      <Grid sm={12} xl={12} sx={{ display: isMobile ? 'none' : "flex", flexWrap: "nowrap", justifyContent: "space-between", width: "100%" }} container spacing={1} >
        {
          !isFromServiceOrderReportPage && (
            <>
              <Grid item sm={2}>

                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    value={fromDate === "" ? ' ' : fromDate}
                    slotProps={{ textField: { size: 'small', fullWidth: 'fullWidth', inputProps: { readOnly: true } } }}
                    label="From Date"
                    maxDate={dayjs(currentDate)}
                    sx={{ width: "100%", marginBottom: '10px', '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ': { borderColor: '#D6D3D3' }, '.MuiInputLabel-root.Mui-error': { color: '#02A0FC' } }}
                    onChange={(date) => handleFromDateSelect(date)} />
                </LocalizationProvider>
              </Grid>


              <Grid item sm={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(date) => handleToDateSelect(date)}
                    value={toDate === "" ? toDate : toDate >= fromDate ? toDate : ' '}
                    maxDate={dayjs(currentDate)}
                    minDate={dayjs(fromDate)}
                    label="To Date"
                    slotProps={{ textField: { size: 'small', fullWidth: 'fullWidth', inputProps: { readOnly: true } } }} sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': { borderColor: '#D6D3D3' }, '.MuiInputLabel-root': { color: '#02A0FC' } }} disabled={fromDate === '' ? true : false} />
                </LocalizationProvider>
              </Grid>
            </>
          )
        }


        <Grid item sm={2.2} md={2.4} xl={2.7} sx={isFromServiceOrderReportPage ? GridStyle : null}>
          <FormControl fullWidth >
            <TextField
              size="small"
              id="postCode"
              label="Post Code"
              //   disabled={isSearchAddressExit ? true : false}
              variant="outlined"
              value={postCode ? postCode : ""}
              onChange={(event) => {
                setPostCode(event.target.value);

              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={2.2} md={2.4} xl={2.7} >
          <FormControl fullWidth>
            <TextField
              size="small"
              id="serviceOrderNumber"
              label="Job Number"
              variant="outlined"
              value={serviceOrderNumber ? serviceOrderNumber : ''}
              onChange={(event) => {

                setServiceOrderNumber(event.target.value);

              }}
            />
          </FormControl>
        </Grid>


        <Grid item sx={{ width: "20%", display: 'flex' }}>
          <FormControl fullWidth>
            <TextField
              size="small"
              id="customerAddress"
              label="Customer Address"
              variant="outlined"
              value={customerAddress ? customerAddress : ''}
              onChange={(event) => {
                setCustomerAddress(event.target.value);
              }}
            />
          </FormControl>
        </Grid>





        {/******************** Filter and search componets *********************/}
        {/* ******************Filter Button********************  */}

        <Grid item
        // sm={1}
        // md={0.6}
        // lg={0.5}
        >
          <Box sx={{ boxSizing: '' }}>
            <Button
              size="small"
              ref={anchorRef}
              sx={{
                alignItem: "center",
                alignSelf: "center",
                minWidth: 0,
                padding: '0.45rem 0.5rem',
                borderRadius: "5px",
                border: "1px solid #D6D3D3",
              }}

              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              disabled={disabledProp}
            // startIcon={<TuneIcon />}
            >
              <TuneIcon />
            </Button>
            <Menu
              // anchorEl={anchorRef.current}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              disabled={disabledProp}
              autoFocusItem={open}
              id="composition-menu"
              aria-labelledby="composition-button"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ '&:hover': 'none' }}
            >
              <Grid>
                <Grid sx={{ marginBottom: '5px' }}>
                  {filterNames.map((name) => (
                    <MenuItem key={name} value={name} sx={{ padding: '1px' }} onChange={() => handleChange(name)}
                      onClick={() => handleChange(name)}
                    >
                      <Checkbox
                        checked={selectedFilter.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>

                  ))}
                </Grid>

                <MenuItem
                  sx={{ margin: '0px 80px', '&:hover': { backgroundColor: 'white' } }}
                  disableTouchRipple
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={applyFilter}
                  >
                    Apply
                  </Button>
                  {/* <ListItemText>Web Clipboard</ListItemText> */}
                </MenuItem>
              </Grid>
            </Menu>
          </Box>
          {/* </FormControl> */}
        </Grid>

        {/* *********************search Button************************* */}

        <Grid
          item
          gap={1}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "2px" }}>
            <Button className="button-search" onClick={search} variant="contained" color="primary" disabled={disabledProp} sx={{ height: '2.4rem' }}
            >
              <SearchIcon />
            </Button>
          </Grid>
          <Grid item >
            <Button className="button-reset" size="small" onClick={() => resetAllFilters()} variant="contained" color="primary" disabled={disabledProp}>
              Reset
            </Button>
          </Grid>
          {
            isFromServiceOrderReportPage && (
              <Grid item >
                <Button sx={{ padding: "11px 7px" }} size="small" variant="contained" color="primary" disabled={disabledProp}
                >
                  <BookmarkIcon sx={{ fontSize: "26px" }} />
                </Button>
              </Grid>
            )
          }


        </Grid>


        {/******************** Filter and search componets *********************/}

      </Grid>


      <Grid sx={{ marginTop: "0.3rem", display: isMobile ? 'none' : 'flex', justifyContent: "flex-start", width: "100%" }} container spacing={1}>
        {selectedFilter?.map((item) => (
          <>
            {
              showFilter && item === "Order Status" && (
                <Grid item sm={2} sx={{ width: "20%" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="Service Order Status">Service Order Status</InputLabel>

                    <Select
                      labelId="Service Order Status"
                      label="Service Order Status"

                      name="status"
                      value={serviceOrderStatus}
                      onChange={(event) => {
                        handleSTatus(event)
                      }}
                    >
                      {
                        status && status.map((item) => (

                          <MenuItem value={item.orderStatusName}>{item.orderStatusName}</MenuItem>


                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>

              )
            }
            {
              showFilter && item === "Order Status" && (
                <Grid item sm={2} sx={{ width: "20%" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="Service Order Sub Status">Service Order Sub Status</InputLabel>

                    <Select
                      disabled={disabledSubStatus}
                      labelId="Service Order Sub Status"
                      label="Service Order Sub Status"


                      name="status"
                      value={serviceOrderSubStatus}
                      onChange={(event) => {
                        handleSubSTatus(event)
                      }}
                    >
                      {
                        subStatus && subStatus.map((item) => (
                          <MenuItem value={item?.orderSubStatusName}>{item?.orderSubStatusName}</MenuItem>))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            }

            {
              showFilter && item === "Customer Phone" && (
                <Grid item sm={2} sx={{ width: "20%" }}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="customerPhone"
                      label="Customer Phone"
                      variant="outlined"
                      value={customerPhone ? customerPhone : ''}
                      onChange={(event) => {
                        setCustomerPhone(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )
            }

            {
              showFilter && item === "Customer First Name" && (
                <Grid item sm={2} sx={{ width: "20%" }}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="customerFirstName"
                      label="Customer First Name"
                      variant="outlined"
                      value={customerFirstName ? customerFirstName : ''}
                      onChange={(event) => {
                        setCustomerFirstName(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )
            }

            {
              showFilter && item === "Customer Email" && (
                <Grid item sm={2} sx={{ width: "20%" }}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="customerEmail"
                      label="Customer Email"
                      variant="outlined"
                      value={customerEmail ? customerEmail : ''}
                      onChange={(event) => {
                        setCustomerEmail(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )
            }
            {/* {
              showFilter && item === "Customer Address" && (
                <Grid item sx={{ width: "20%", display: 'flex' }}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="customerAddress"
                      label="Customer Address"
                      variant="outlined"
                      value={customerAddress ? customerAddress : ''}
                      onChange={(event) => {
                        setCustomerAddress(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )
            } */}

            {
              showFilter && item === "Customer Last Name" && (
                <Grid item sm={2}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="customerLastName"
                      label="Customer Last Name"
                      variant="outlined"
                      value={customerLastName ? customerLastName : ''}
                      onChange={(event) => {
                        setCustomerLastName(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )
            }

          </>
        ))}
      </Grid>

      <Grid sx={{ display: isMobile ? 'flex' : "none", flexWrap: "nowrap", justifyContent: "space-between", width: "100%" }} container spacing={1}>
        <Grid item sm={2.2} md={2.4} xl={2.7} ml={2} >
          <FormControl fullWidth>
            <TextField
              size="small"
              id="serviceOrderNumber"
              label="Job Number"
              variant="outlined"
              value={serviceOrderNumber ? serviceOrderNumber : ''}
              onChange={(event) => {

                setServiceOrderNumber(event.target.value);

              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Box>
            <Button
              size="small"
              ref={anchorRef}
              sx={{
                alignItem: "center",
                alignSelf: "center",
                minWidth: 0,
                padding: '0.35rem 0.5rem',
                borderRadius: "5px",
                border: "1px solid #D6D3D3",
                height: '2.5rem'
              }}

              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggleMobile}
              disabled={disabledProp}
            // startIcon={<TuneIcon />}
            >
              <TuneIcon />
            </Button>

            <Dialog
              open={openDialog}
            // display={ isMobile? 'flex' : 'none'}
            >
              <DialogTitle sx={{ textAlign: 'center', display: isMobile ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', paddingTop: '1rem' }} >
                <Typography sx={{ fontWeight: '600' }}> Filters</Typography>
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleToggleDialogClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>


                <Grid item sx={{ marginBottom: '5px', padding: '1rem 1rem' }} spacing={3}>

                  <Grid item sx={12} sm={2}>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >

                      <DatePicker
                        value={fromDate === '' ? '' : fromDate}
                        slotProps={{ textField: { size: 'small', fullWidth: 'fullWidth', inputProps: { readOnly: true } } }}
                        label="From Date"
                        maxDate={dayjs(currentDate)}
                        sx={{ width: "100%", marginBottom: '10px', '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ': { borderColor: '#D6D3D3' }, '.MuiInputLabel-root.Mui-error': { color: '#02A0FC' } }}
                        onChange={(date) => handleFromDateSelect(date)} />
                    </LocalizationProvider>
                  </Grid>


                  <Grid item sx={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                      <DatePicker
                        onChange={(date) => handleToDateSelect(date)}
                        value={toDate === "" ? toDate : toDate >= fromDate ? toDate : ' '}
                        maxDate={dayjs(currentDate)}
                        minDate={dayjs(fromDate)}
                        label="To Date"
                        slotProps={{ textField: { size: 'small', fullWidth: 'fullWidth', inputProps: { readOnly: true } } }} sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': { borderColor: '#D6D3D3' }, '.MuiInputLabel-root': { color: '#02A0FC' } }} disabled={fromDate === '' ? true : false} />
                    </LocalizationProvider>
                  </Grid>



                  <Grid item xs={12} sx={{ width: "100%" }} mt={2} >
                    <FormControl fullWidth size="small">
                      <InputLabel id="Service Order Status">Service Order Status</InputLabel>

                      <Select
                        labelId="Service Order Status"
                        label="Service Order Status"

                        name="status"
                        value={serviceOrderStatus}
                        onChange={(event) => {
                          handleSTatus(event)
                        }}
                      >
                        {
                          status && status.map((item) => (

                            <MenuItem value={item.orderStatusName}>{item.orderStatusName}</MenuItem>


                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item sx={{ width: "100%" }} mt={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="Service Order Sub Status">Service Order Sub Status</InputLabel>

                      <Select
                        disabled={disabledSubStatus}
                        labelId="Service Order Sub Status"
                        label="Service Order Sub Status"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}

                        name="status"
                        value={serviceOrderSubStatus}
                        onChange={(event) => {
                          handleSubSTatus(event)
                        }}
                      >
                        {
                          subStatus && subStatus.map((item) => (
                            <MenuItem value={item?.orderSubStatusName}>{item?.orderSubStatusName}</MenuItem>))
                        }
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item sx={{ width: "100%" }} mt={2}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="customerFirstName"
                        label="Customer First Name"
                        variant="outlined"
                        value={customerFirstName ? customerFirstName : ''}
                        onChange={(event) => {
                          setCustomerFirstName(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} mt={2}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="customerLastName"
                        label="Customer Last Name"
                        variant="outlined"
                        value={customerLastName ? customerLastName : ''}
                        onChange={(event) => {
                          setCustomerLastName(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ width: "100%" }} mt={2}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="customerPhone"
                        label="Customer Phone"
                        variant="outlined"
                        value={customerPhone ? customerPhone : ''}
                        onChange={(event) => {
                          setCustomerPhone(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ width: "100%" }} mt={2}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="customerEmail"
                        label="Customer Email"
                        variant="outlined"
                        value={customerEmail ? customerEmail : ''}
                        onChange={(event) => {
                          setCustomerEmail(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ width: "100%", display: 'flex' }} mt={2}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="customerAddress"
                        label="Customer Address"
                        variant="outlined"
                        value={customerAddress ? customerAddress : ''}
                        onChange={(event) => {
                          setCustomerAddress(event.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={2} mt={2} >
                    <FormControl fullWidth >
                      <TextField
                        size="small"
                        id="postCode"
                        label="Post Code"
                        //   disabled={isSearchAddressExit ? true : false}
                        variant="outlined"
                        value={postCode ? postCode : ""}
                        onChange={(event) => {
                          setPostCode(event.target.value);

                        }}
                      />
                    </FormControl>
                  </Grid>


                  <Grid container sx={{ display: 'flex', justifyContent: 'space-evenly' }} mt={4}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={search}
                      disabled={disabledProp}
                    >
                      Apply
                    </Button>
                    <Grid item >
                      <Button className="button-reset" size="large" onClick={() => resetAllFilters()} variant="contained" color="primary" disabled={disabledProp}>
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </Box>
          {/* </FormControl> */}
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "2px" }}>
          <Button className="button-search" onClick={search} variant="contained" color="primary" disabled={disabledProp} sx={{ height: '2.4rem' }}
          >
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>

    </>
  );
};

export default JobsFilters;

