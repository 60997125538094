import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customer: {
    firstName: "",
    lastName: "",
    businessName: "",
    address1: "",
    address2: "",
    city: "",
    stateProvince: "",
    zipCode: "",
    cellPhone: "",
    otherPhone: "",
    countryCode: "USA",
    dealerCustomerID: "",
    email: "",
    languageCode: "en-us",
  },
  contract: {
    contractPurchaseDate: "",
    contractOtherTax: 0,
    contractRetailPrice: 1,
    contractSalesTax: 0,
    contractSKU: "",
    contractSourceName: "",
    dealerID: "NA",
    dealerInvoiceNumber: "",
  },
  product: {
    catalogItemNumber: "1",
    manufacturerName: "",
    modelName: "", //
    modelNumber: "",
    oemLaborWarrantyTerm: "0",
    oemMajorCompTerm: "0",
    oemOtherTerm: "0",
    oemPartsWarrantyTerm: "0",
    productCondition: "N",
    productDeliveryInstallDate: "",
    productPurchaseDate: "",
    productPurchasePrice: 3000,
    serialNumber: "",
    industryCode: "",
    subCategory: "",
  },
  service: {
    failureDate: "",
    symptomCode: "",
    failureNote: "",
    functionalityStatus: "",
    complaintCode: "",
  },
  appointment: {
    consumerRequestDate: "",
    consumerRequestTime: "Morning",
    specialInstructions: "",
  },
  warranty: {
    warrantyType: "",
    warrantyTypeId: "",
  },
  general1: "",
  general2: "",
  general3: "",
  general4: "",
  timeZone: "",
  timeZoneMinuteDifference: -330,
};

const CreatePSPSlice = createSlice({
  name: 'CreatePSPSlice',
  initialState,
  reducers: {
    saveCustomerDetails: (state, action) => {
      state.customer = action.payload
    },
    saveServicerDetails: (state, action) => {
      state.service = action.payload;

    },
    saveContractDetails: (state, action) => {

      state.contract = action.payload;

    },
    saveProductDetails: (state, action) => {
      state.product = action.payload;
    },
    saveAppointmentDetails: (state, action) => {
      state.appointment = action.payload;
    },
    saveWarrantyDetails: (state, action) => {
      state.warranty = action.payload
    }
  }

});
export const { saveCustomerDetails, saveProductDetails, saveWarrantyDetails, saveAppointmentDetails, saveContractDetails, saveServicerDetails } = CreatePSPSlice.actions;
export default CreatePSPSlice.reducer;