import { Avatar, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Typography } from "@mui/material";
import { StyledTextField } from "../../../components/StyledComponent/StyledComponent";
import { useState } from "react";
import { postRequest } from "../../../axios/axios";
import { toast } from "react-toastify";
import SetPasswordSchema from "./SetPasswordSchema";
import { Form, useFormik } from "formik";
import { Formik } from "formik";
import LoginSlice from "../LoginSlice";
import { handleIsPasswordCreated } from "../LoginSlice"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { esES } from "@mui/x-date-pickers";


const ResetPassword = ({ userEmail, handleSetLoginComponent }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)

  const [passLength, setPassLength] = useState(true)
  const [isUpperCharPresent, setIsUpperCharPresent] = useState(true)
  const [isLowerCharPresent, setIsLowerCharPresent] = useState(true)
  const [isNumberisPresent, setIsNumberIsPresent] = useState(true)
  const [isSpecialCharPresent, setIsSpecialCharPresent] = useState(true)

  const [showPassHint, setShowPassHint] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getEmailFromRedux = useSelector((state) => {
    return state?.Login?.userDetail?.email
  })
  const getOtpFromRedux = useSelector((state) => {
    return state?.Login?.otp
  })
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  let defaultValue = {
    newPassword: "",
    ConfirmPassword: ""
  }

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: defaultValue,
    validationSchema: SetPasswordSchema,
    onSubmit: (values) => {
      handleSetNewPassword()
    },

  });
  const [errroMsg, setErrorMsg] = useState(null)


  const handleSetNewPassword = async () => {
    if (passLength || isUpperCharPresent || isLowerCharPresent || isNumberisPresent || isSpecialCharPresent) {
      setShowPassHint(true)
    } else {
      setShowPassHint(false)
    }
    if (!formik.isValid) return;
    setIsDisabled(true)
    const data = {
      "email": getEmailFromRedux ? getEmailFromRedux : userEmail,
      "otp": getOtpFromRedux ? getOtpFromRedux : "",
      "password": formik.values.ConfirmPassword

    }
    const res = await postRequest("/api/reset/password", data, "sdLogin")
    if (res.data.isSuccess) {
      if (res.data.data.isPasswordExist && res.data.data.isEmailExist) {
        dispatch(handleIsPasswordCreated(true));
      } else {
        setIsDisabled(false)
        toast.error(res?.data?.data)
      }
    }
    else if (!res.data.isSuccess) {
      setIsDisabled(false)

      toast.error(res.data.message)
      // toast.error("Something went Wrong")

    }
    else {
      setIsDisabled(false)
      toast.error(res?.data?.message)
    }
  }



  const handleValidationCheck = (value) => {
    const containsNumber = /\d/;
    const containsSpecialCharacter = /[^a-zA-Z0-9]/;
    const containsUppercase = /[A-Z]/;
    const containsLowerCase = /[a-z]/;
    if (value.length === 0) {
      setIsNumberIsPresent(true)
      setIsSpecialCharPresent(true)
      setIsUpperCharPresent(true)
      setIsLowerCharPresent(true)
      setPassLength(true)
    }
    if (containsNumber.test(value)) {
      setIsNumberIsPresent(false)
    } else {
      setIsNumberIsPresent(true)

    }
    if (containsSpecialCharacter.test(value)) {
      setIsSpecialCharPresent(false)
    } else {
      setIsSpecialCharPresent(true)
    }
    if (containsUppercase.test(value)) {
      setIsUpperCharPresent(false)
    } else {
      setIsUpperCharPresent(true)
    }
    if (containsLowerCase.test(value)) {
      setIsLowerCharPresent(false)
    } else {
      setIsLowerCharPresent(true)
    }
    if (value.length >= 8) {
      setPassLength(false)
    } else {
      setPassLength(true)

    }
    if (passLength && isUpperCharPresent && isLowerCharPresent && isNumberisPresent && isSpecialCharPresent) {
      setIsDisabled(false)
    }
  }

  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: "column", padding: "1rem 2rem" }}>

        <form onSubmit={formik.handleSubmit}>
          {/* <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "5px 0px 20px 0px" }}> */}
          {/* <Avatar sx={{ bgcolor: "#E56A54" }}>CM</Avatar>
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="h7" align="center" color="#040F3E" sx={{ margin: "0px 0px 10px 0px" }}>Welcome Chris Mathews!</Typography>
          </Grid> */}
          <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", }} >
            <Typography variant="h7" align="center" color="#040F3E" sx={{ margin: "0px 0px 10px 0px" }}>Reset your Password</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} item sm={12} >
              {/* <StyledTextField
                size="small"
                fullWidth
                name="newPassword"
                label=" New Password"
                value={formik.values.newPassword}
                onChange={(e) => {
                  formik.setFieldValue("newPassword", e.target.value);
                  // setNewPassword(e.target.value)
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.newPassword && formik.errors.newPassword
                )}
              />
              {Boolean(
                formik.touched.newPassword && formik.errors.newPassword
              ) && (
                  <Typography variant="body2" color="error">
                    {formik.errors.newPassword}
                  </Typography>
                )} */}
              <FormControl fullWidth>
                <InputLabel htmlFor="password" sx={{
                  "&.MuiInputLabel-root": {
                    top: "-5px"
                  }
                }}>{<span style={{ padding: 0, margin: 0, textAlign: "center", color: "#AEB5BC" }}>New Password</span>}</InputLabel>

                <OutlinedInput
                  sx={{
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #F9F9F9",

                    "&.MuiOutlinedInput-root.Mui-disabled": {
                      border: "1px solid #F9F9F9"
                    },
                    "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline ": {
                      border: "1px solid #F9F9F9"

                    }
                  }}
                  size="small"
                  id="newPassword"
                  name="newPassword"
                  value={formik.values.newPassword}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    formik.setFieldValue("newPassword", e.target.value);
                    handleValidationCheck(e.target.value)
                    setShowPassHint(true)

                  }}
                  onBlur={formik.handleBlur}

                  label="New Password"
                  error={Boolean(
                    formik.touched.newPassword && formik.errors.newPassword
                  )}
                />
                {Boolean(
                  formik.touched.newPassword && formik.errors.newPassword
                ) && (
                    <Typography variant="body2" color="error">
                      {formik.errors.newPassword}
                    </Typography>
                  )}
              </FormControl>
            </Grid>
            <Grid xs={12} item sm={12} sx={{ marginTop: "5px" }} >
              {/* <StyledTextField
                size="small"
                fullWidth
                name="ConfirmPassword"
                label=" Confirm Password"
                value={formik.values.ConfirmPassword}
                onChange={(e) => {
                  formik.setFieldValue("ConfirmPassword", e.target.value);
                  // SetConfirmPassword(e.target.value)
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.ConfirmPassword && formik.errors.ConfirmPassword
                )}
              />
              {Boolean(
                formik.touched.ConfirmPassword && formik.errors.ConfirmPassword
              ) && (
                  <Typography variant="body2" color="error">
                    {formik.errors.ConfirmPassword}
                  </Typography>
                )} */}
              <FormControl fullWidth>
                <InputLabel htmlFor="password" sx={{
                  "&.MuiInputLabel-root": {
                    top: "-5px"
                  }
                }}>{<span style={{ padding: 0, margin: 0, textAlign: "center", color: "#AEB5BC" }}>Confirm Password</span>}</InputLabel>

                <OutlinedInput
                  sx={{
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #F9F9F9",

                    "&.MuiOutlinedInput-root.Mui-disabled": {
                      border: "1px solid #F9F9F9"
                    },
                    "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline ": {
                      border: "1px solid #F9F9F9"

                    }
                  }}
                  size="small"
                  id="ConfirmPassword"
                  value={formik.values.ConfirmPassword}
                  name="ConfirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    formik.setFieldValue("ConfirmPassword", e.target.value);
                    setShowPassHint(true)

                  }}
                  onBlur={formik.handleBlur}

                  label="Confirm Password"
                  error={Boolean(
                    formik.touched.ConfirmPassword && formik.errors.ConfirmPassword
                  )}
                />
                {Boolean(
                  formik.touched.ConfirmPassword && formik.errors.ConfirmPassword
                ) && (
                    <Typography variant="body2" color="error">
                      {formik.errors.ConfirmPassword}
                    </Typography>
                  )}
              </FormControl>
            </Grid>
          </Grid>
          {
            errroMsg?.length && errroMsg.length > 0 && (<Grid item >
              <Typography align="left" variant="caption" sx={{ margin: "0px 0px 15px 0px" }} color="red">{errroMsg}</Typography>
            </Grid>)
          }

          <Grid item sx={{ textAlign: "center", marginTop: "20px" }}>
            <Button type="submit" variant="contained" sx={{ textTransform: "none" }}
              // onClick={handleSetNewPassword}
              disabled={isDisabled}
            >Save Password</Button>
          </Grid>

          {
            showPassHint && (
              <Grid component={Paper} elevation={3} style={{ marginTop: "1rem", padding: "1rem", display: "flex", flexDirection: "column" }}>
                {
                  passLength ?
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CancelIcon sx={{ color: "red" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>Password must be at least 8 characters</Typography>
                    </Grid> :
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CheckCircleSharpIcon sx={{ color: "green" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>Password must be at least 8 characters</Typography>
                    </Grid>

                }
                {
                  isUpperCharPresent ?
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CancelIcon sx={{ color: "red" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one uppercase letter (A-Z)</Typography>
                    </Grid> :
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CheckCircleSharpIcon sx={{ color: "green" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one uppercase letter (A-Z)</Typography>
                    </Grid>

                }
                {
                  isLowerCharPresent ?
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CancelIcon sx={{ color: "red" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one lowercase letter <span style={{ textTransform: "none" }}>(a-z)</span></Typography>
                    </Grid> :

                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CheckCircleSharpIcon sx={{ color: "green" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one lowercase letter  <span style={{ textTransform: "none" }}>(a-z)</span></Typography>
                    </Grid>
                }
                {
                  isNumberisPresent ?
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CancelIcon sx={{ color: "red" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one number (0-9)</Typography>
                    </Grid> :
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CheckCircleSharpIcon sx={{ color: "green" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one number (0-9)</Typography>
                    </Grid>
                }
                {
                  isSpecialCharPresent ?
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CancelIcon sx={{ color: "red" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one special Character (<span style={{ textTransform: "none" }}>eg</span>,@!#$%^&*)</Typography>
                    </Grid> :
                    <Grid item sx={{ display: "flex", padding: "5px 0px" }}>
                      <CheckCircleSharpIcon sx={{ color: "green" }} />
                      <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>At least one special Character (<span style={{ textTransform: "none" }}>eg</span>,@!#$%^&*)</Typography>
                    </Grid>
                }

              </Grid>
            )
          }


        </form>
      </Grid>
    </>
  )
}
export default ResetPassword;