import { useState, useRef, React } from 'react'
import {
    Grid,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Stack,
    Checkbox,
    ListItemText,
    useStepContext,
    IconButton
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import InputLabel from "@mui/material/InputLabel";
import "../../utils/ComponentContainer.scss"
import "./Servicer.scss"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ReactComponent as Edit } from "../../assets/edit.svg"
import { ReactComponent as Find } from "../../assets/find.svg"
import { ReactComponent as FilterIcon } from "../../assets/filter.svg"
import Pagination from '@mui/material/Pagination';
import SearchIcon from "@mui/icons-material/Search";
import { Button, Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useFetch from "../../utils/useFetch";
import Menu from '@mui/material/Menu';
import axios from 'axios';
import { StyledScrollbar, StyledTextField } from "../../components/StyledComponent/StyledComponent.js";
import { handleLoginPage } from "../../pages/Login/LoginSlice.js"
import LoginSlice from '../../pages/Login/LoginSlice.js';
// import { useFormik } from 'formik';
// import ServicerSchema from '../Servicer/ServicerSchema'
import { changeGlobalViewMode, saveServicer, saveStatus } from '../AccountDetails/accountDetailsSlice';
import { getRequest } from '../../axios/axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Industry } from '../../utils/Head.js';
import { toast } from 'react-toastify';
import { Email } from '@mui/icons-material';


const filterNames = [
    "Servicer Name",
    "Post Code",
    "Status",
    "Industry",
    "Email",
    "Phone",
    "customerAddress"
];


const Servicer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.sessionStorage.setItem("isLoginPage", true)
        dispatch(handleLoginPage(false))
    })

    const { data: countryData } = useFetch("getCountryList", "sdProvider");
    const { data: statusData } = useFetch("getStatusList", "sdIndustry");
    const { data: IndustryList } = useFetch("getIndustryList", "sdIndustry");
    const [isSearch, setIsSearch] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [servicer, setServicer] = useState(null)
    const [triggerFetch, setTriggerFetch] = useState(false);
    const navigate = useNavigate();
    const [offset, setOffset] = useState(0)
    const [totalEntries, setTotalEntries] = useState(0)
    const [totalPages, setTotalpages] = useState(0)
    const [page, setPage] = useState(1)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [postCode, setPostCode] = useState('');
    const [ServicerName, setServicerName] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [customerAddress, setCustomerAddress] = useState('')
    const [servicerAccountId, setServicerAccountId] = useState();
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState({
        // statusName: "Contract Signed",
        // statusId: 1
        statusName: "",
        statusId: ""
    });
    const [loadingCities, setLoadingCities] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedFilter, setFilterNames] = useState([]);
    const [disabledProp, setDisabledProp] = useState(false);
    const [query, setQuery] = useState('');
    const [searchOffset, setSearchOffset] = useState(0)
    const [selectedState, setSelectedState] = useState(undefined);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState(undefined);
    const [selectPhone, setSelectPhone] = useState("")

    useEffect(() => {
        // dispatch(saveStatus("Contract Signed"));
    }, [])

    const handleOnSearch = async () => {
        const response = await getRequest(`/api/reportMaster/servicerDetail/data?${query}&offset=${searchOffset}&limit=10`, 'sdMaster');
        if (response.data.isSuccess) {
            setServicer(response.data.data.servicerData || [])
            setTotalEntries(response.data.data.totalCount)
        }
        else {
            toast.error(response.data.message)
        }
        // setIsSearch(false)
        setPage(0)
    }

    const handleChange = (name) => {
        if (selectedFilter.includes(name)) {
            resetFilter(name)
            const selectItems = selectedFilter.filter(function (item) {
                return item !== name;
            });
            setFilterNames([...selectItems]);
        } else {
            setFilterNames([...selectedFilter, name]);
        }
    };


    const handleToggle = () => {
        setShowFilter(false)
        setOpen((prevOpen) => !prevOpen);
        //setFilterNames([])

    };


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const prevOpen = useRef(open);


    useEffect(() => {

        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);


    const applyFilter = () => {
        setOpen(false)
        setShowFilter(true)

    };


    const resetAllFilters = () => {
        setSelectedCountry("")
        getDefaultCountry()
        setSelectedStatus(undefined)
        setSelectedIndustry(undefined)
        setFilterNames([]);
        setShowFilter(false);
        setSelectedStatus("")
        setStateList([])
        setSelectedCity("")
        setSelectedState("")
        setSelectedIndustry("")
        setPostCode("")
        setServicerAccountId("")
        setEmailAddress("")
        setCustomerAddress("")
        setServicerName("")
        setSelectPhone("")
        setServicer([])
        setCityList([])
        setIsSearch(false)
        setSearchOffset(0)
        setFilterNames([])
        setOffset(0)
        setPage(1)
        setTotalEntries("")
        get()

    }

    useEffect(() => {
        dispatch(handleLoginPage(false))
        if (servicer?.length === 0) {

            // get();
        }
    }, [servicer]);


    const resetFilter = (name) => {
        switch (name) {
            case 'Status':
                setSelectedStatus(undefined)
                break;
            case 'Industry':
                setSelectedIndustry(undefined)
                break;
            case 'City':
                setSelectedCity(undefined)
                break;
            case 'Country':
                setSelectedCountry(undefined)
                break;
            case 'State':
                setSelectedState(undefined)
                break;
            default:
                break;
        }


    }

    const fetchCitiesByState = async (stateId) => {
        // const res = await getRequest(
        //     `/api/getStateDetail?countryId=${selectedCountry?.countryId}`,
        //     "sdProvider"
        // );
        const response = await getRequest(`/api/getCityDetail?stateId=${selectedState?.stateId}`, 'sdProvider');

        if (response.data.isSuccess) {

            setCityList(response.data.items || [])
        }

        return [];
    };



    useEffect(() => {
        const fetchCities = async () => {
            if (selectedState?.stateId) {
                setLoadingCities(true);


                const selectedCity = await fetchCitiesByState(selectedState.stateId);

                setSelectedCity(selectedCity);
                setLoadingCities(false);
            }
        };

        fetchCities();
    }, [selectedState]);

    useEffect(() => {
        if (!selectedCountry) return;
        const getState = async () => {
            const res = await getRequest(
                `/api/getStateDetail?countryId=${selectedCountry?.countryId}`,
                "sdProvider"
            );
            setStateList(res.data.items);
        };
        getState();
    }, [selectedCountry?.countryId]);

    useEffect(() => {

    }, [])

    useEffect(() => {
        buildQuery()
        setIsSearch(false)
        setSearchOffset(0)
    }, [selectedCountry, selectedState, ServicerName, selectedStatus, EmailAddress, postCode, servicerAccountId, selectedIndustry, selectedCity, selectPhone, customerAddress])

    const buildQuery = () => {

        let queryString = ''
        if (selectedStatus?.statusId) {
            queryString += `&statusId=${selectedStatus.statusId}`;
        }
        if (selectedState?.stateId) {
            queryString += `&stateId=${selectedState.stateId}`;
        }
        if (selectedCity) {
            queryString += `&cityName=${selectedCity}`;
        }
        if (selectedCountry?.countryId) {
            queryString += `&countryId=${selectedCountry.countryId}`;
        }
        if (postCode) {
            queryString += `&postCode=${postCode}`;
        }
        if (ServicerName) {
            let encodedServicerName = encodeURIComponent(ServicerName);
            queryString += `&serviceProvider=${encodedServicerName}`;
        }
        if (EmailAddress) {
            queryString += `&email=${EmailAddress}`;
        }
        if (servicerAccountId) {
            queryString += `&servicerAccountId=${servicerAccountId}`
        }
        if (selectedIndustry?.industryId) {
            queryString += `&industryId=${selectedIndustry?.industryId}`
        }
        if (selectPhone) {
            queryString += `&phone=${selectPhone}`;
        }
        if (customerAddress) {
            queryString += `&customerAddress=${customerAddress}`;
        }
        setQuery(queryString)
    }

    useEffect(() => {
        if (countryData) {
            getDefaultCountry()
        }
    }, [countryData])
    const getDefaultCountry = () => {
        let defaultCountry = countryData.filter((id) => (

            id.countryName === "United States of America" || id.countryCode === 237
        ))
        if (defaultCountry) {
            setSelectedCountry(defaultCountry[0])
        }
    }

    const handlePageChange = (event, value) => {
        if (isSearch) {
            setPage(value);
            const pageNumber = Number(value) - 1
            setOffset(pageNumber)
        }
        else {
            setPage(value);
            const pageNumber = Number(value) - 1
            setOffset(pageNumber)
        }

    }

    const view = (data) => {
        dispatch(changeGlobalViewMode('noPost'))
        dispatch(saveServicer(data))
        sessionStorage.setItem("servicerId", data.servicerId);
        navigate(`/NetworkManagement/Servicer/AccountDetails/${data.servicerId}`, { replace: true })
    }

    useEffect(() => {
        if (totalEntries % 10 === 0) {
            let calculatedTotalPages = totalEntries / 10;
            setTotalpages(calculatedTotalPages)
        } else {
            let calculatedTotalPages = Math.ceil(totalEntries / 10);
            setTotalpages(calculatedTotalPages)
        }
    }, [servicer])

    const get = async () => {
        let response = await getRequest(`/api/reportMaster/servicerDetail/data?offset=${offset}&limit=10&countryId=${selectedCountry?.countryId}`, "sdMaster"
        )

        if (response.data.isSuccess) {
            setTotalEntries(response?.data?.data?.totalCount)
            setServicer(response.data?.data?.servicerData || [])
        }
        else {
            setServicer([])
        }
    }
    useEffect(() => {
        if (isSearch) {
            handleOnSearch()
            // setIsSearch(false)
        }
    }, [searchOffset, isSearch])
    useEffect(() => {
        // if (isSearch) {
        //     handleOnSearch();
        // } else {
        if (selectedCountry?.countryId) {
            get();
        }
        // }
    }, [offset, selectedCountry?.countryId]);

    return (
        <div style={{ position: "relative" }}>
            <div style={{ width: "100%", backgroundColor: "#FFF", height: "8vh", marginTop: 0 }}>
                <h2 style={{ marginTop: 0, paddingTop: 0 }}>Search Servicer</h2>

            </div>

            <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", marginTop: "0.5rem" }}>
                <Grid item sm={12}>
                    <Grid container spacing={2} sx={{ display: "flex" }}>
                        <Grid item sm={2.4}>

                            <FormControl fullWidth size="small">
                                <InputLabel id="country-select-label">Country</InputLabel>
                                <Select
                                    labelId="country-select-label"
                                    id="country-select"
                                    value={selectedCountry ? selectedCountry.countryName : ''}
                                    label="Country"
                                    onChange={(event) => {
                                        setSelectedCity("")
                                        const selectedCountryName = event.target.value;
                                        const selectedCountry = countryData?.find(
                                            (country) => selectedCountryName === country.countryName
                                        );

                                        setSelectedCountry(selectedCountry);
                                    }}
                                >

                                    {countryData && Array.isArray(countryData) && countryData.length > 0
                                        ? countryData
                                            .sort((a, b) =>
                                                a.countryName === "United States of America"
                                                    ? -1
                                                    : b.countryName === "United States of America"
                                                        ? 1
                                                        : a.countryName.localeCompare(b.countryName)
                                            )
                                            .map((country) => (
                                                <MenuItem style={{ zIndex: "100000000000" }} key={country.countryId} value={country.countryName}>
                                                    {country.countryName}
                                                </MenuItem>
                                            ))
                                        : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2.4}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="state-select-label">State</InputLabel>
                                <Select

                                    labelId="state-select-label"
                                    id="state-select"
                                    value={selectedState ? selectedState.stateName : ''}
                                    label="State"
                                    onChange={(event) => {
                                        const selectedStateName = event.target.value;
                                        const selected = stateList?.find(
                                            (state) => selectedStateName === state.stateName
                                        );
                                        setSelectedState(selected);

                                    }}
                                >
                                    {stateList?.length > 0 ? (
                                        stateList?.map((state) => (
                                            <MenuItem key={state.stateId} value={state.stateName}>
                                                {state.stateName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>Please Select  Country</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2.4}>
                            <FormControl fullWidth>
                                <TextField
                                    size='small'
                                    value={selectedCity ? selectedCity : ""}
                                    label="City"
                                    onChange={(event) => {


                                        setSelectedCity(event.target.value);
                                    }}
                                >

                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2.4} >
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="servicerAccountId"
                                    label="Servicer Account Number"
                                    variant="outlined"
                                    value={servicerAccountId ? servicerAccountId : ''}
                                    onChange={(event) => {

                                        setServicerAccountId(event.target.value);

                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={2.4}>

                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    id="ServicerName"
                                    label="Servicer Name"
                                    variant="outlined"
                                    value={ServicerName}
                                    onChange={(event) => {
                                        setServicerName(event.target.value);

                                    }}
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                </Grid>


                <Grid item sm={12}>
                    <Grid container spacing={2} sx={{ display: "flex" }}>

                        <Grid item sm={2.4}>

                            <FormControl fullWidth size="small">
                                <InputLabel id="industry-select-label">Industry</InputLabel>
                                <Select
                                    labelId="industry-select-label"
                                    id="industry-select"
                                    value={selectedIndustry ? selectedIndustry?.industryName : ""}

                                    label="Industry"
                                    onChange={(event) => {
                                        const selectedName = event.target.value;
                                        const selected = IndustryList?.find(
                                            (country) => selectedName === country.industryName
                                        );
                                        setSelectedIndustry(selected);

                                    }}
                                >
                                    {IndustryList?.map((value) => (
                                        <MenuItem
                                            key={value.industryId}
                                            value={value.industryName}
                                            sx={{ ...StyledScrollbar, }}
                                        >
                                            {value.industryName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2.4}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="Status-select-label">Status</InputLabel>
                                <Select
                                    labelId="Status-select-label"
                                    id="Status-select"
                                    value={selectedStatus ? selectedStatus.statusName : ""}
                                    label="Status"
                                    onChange={(event) => {
                                        dispatch(saveStatus(event.target.value));
                                        const selectedStatusName = event.target.value;
                                        const selectedStatus = statusData?.find(
                                            (Status) => selectedStatusName === Status.statusName
                                        );
                                        setSelectedStatus(selectedStatus);
                                    }}
                                >
                                    {statusData?.map((Status) => (
                                        <MenuItem
                                            key={Status.statusId}
                                            value={Status.statusName}
                                            selected={
                                                selectedStatus && selectedStatus.statusId === Status.statusId
                                            }
                                        >
                                            {Status.statusName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2.4}>

                            <FormControl fullWidth>
                                <TextField
                                    type='number'
                                    size="small"
                                    id="postCode"
                                    label="postCode"
                                    variant="outlined"
                                    value={postCode ? postCode : ''}
                                    onChange={(event) => {

                                        setPostCode(event.target.value);

                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item sm={2.4}>
                            <FormControl fullWidth>
                                <TextField
                                    type='number'
                                    size="small"
                                    id="phone"
                                    label="Phone Number"
                                    variant="outlined"
                                    value={selectPhone ? selectPhone : ''}
                                    onChange={(event) => {

                                        setSelectPhone(event.target.value);

                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item sm={2.4}>

                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="EmailAddress"
                                    label="Email Address"
                                    variant="outlined"
                                    value={EmailAddress ? EmailAddress : ''}
                                    onChange={(event) => {

                                        setEmailAddress(event.target.value);

                                    }}
                                />
                            </FormControl>
                        </Grid>




                        <Grid item container sm={3} xl={2.4} sx={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap" }}>
                            <Grid spacing={2} sm={12} sx={{ display: "flex", justifyContent: "space-between" }}>



                                <Grid item >
                                    {/* <Box> */}
                                    <Button
                                        size="small"
                                        ref={anchorRef}
                                        sx={{
                                            alignItem: "center",
                                            alignSelf: "center",
                                            minWidth: 0,
                                            padding: '0.45rem 0.5rem',
                                            borderRadius: "5px",
                                            border: "1px solid #D6D3D3",
                                            marginLeft: 'auto',
                                        }}

                                        id="composition-button"
                                        aria-controls={open ? "composition-menu" : undefined}
                                        aria-expanded={open ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        disabled={disabledProp}
                                    >
                                        <TuneIcon />
                                    </Button>
                                    {/* <Menu
                                            anchorEl={anchorRef.current}
                                            open={open}
                                            onClose={handleClose}
                                            disabled={disabledProp}
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            MenuListProps={{
                                                "aria-labelledby": "basic-button",
                                            }}
                                            sx={{ '&:hover': 'none' }}
                                        >
                                            <Grid>
                                                <Grid sx={{ marginBottom: '5px' }}>
                                                    {filterNames.map((name) => (
                                                        <MenuItem key={name} value={name} sx={{ padding: '1px' }} onChange={() => handleChange(name)}
                                                            onClick={() => handleChange(name)}
                                                        >
                                                            <Checkbox
                                                                checked={selectedFilter.indexOf(name) > -1}
                                                            />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>

                                                    ))}
                                                </Grid>

                                                <MenuItem
                                                    sx={{ margin: '0px 80px', '&:hover': { backgroundColor: 'white' } }}
                                                    disableTouchRipple
                                                >
                                                    <Button
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={applyFilter}
                                                    >
                                                        Apply
                                                    </Button>

                                                </MenuItem>
                                            </Grid>
                                        </Menu> */}
                                    {/* </Box> */}

                                </Grid>

                                {/* *********************search Button************************* */}
                                <Grid item >
                                    <Button sx={{ padding: "7px", marginLeft: "20px" }} onClick={() => setIsSearch(true)} variant="contained" color="primary" disabled={disabledProp}
                                    // sx={{borderRadius: "5px", padding: "0px 10px", minWidth: "100%"}}
                                    >
                                        <SearchIcon />
                                    </Button>
                                </Grid>
                                {/* *********************Reset Button************************* */}
                                <Grid item >
                                    <Button sx={{ padding: "7px", marginLeft: "20px" }} size="small" onClick={resetAllFilters} variant="contained" color="primary" disabled={disabledProp}
                                    >
                                        Reset
                                    </Button>

                                </Grid>




                                {/******************** Filter and search componets *********************/}
                                <Grid sx={{ marginTop: 0 }} container spacing={1}>
                                    {selectedFilter?.map((item) => (

                                        <>

                                            {showFilter && item === "Status" && (
                                                <Grid item xs={2.4}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="Status-select-label">Status111</InputLabel>
                                                        <Select
                                                            labelId="Status-select-label"
                                                            id="Status-select"
                                                            value={selectedStatus ? selectedStatus.statusName : ""}
                                                            label="Status"
                                                            onChange={(event) => {
                                                                const selectedStatusName = event.target.value;
                                                                const selectedStatus = statusData?.find(
                                                                    (Status) => selectedStatusName === Status.statusName
                                                                );
                                                                setSelectedStatus(selectedStatus);
                                                            }}
                                                        >
                                                            {statusData?.map((Status) => (
                                                                <MenuItem
                                                                    key={Status.statusId}
                                                                    value={Status.statusName}
                                                                    selected={
                                                                        selectedStatus && selectedStatus.statusId === Status.statusId
                                                                    }
                                                                >
                                                                    {Status.statusName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>


                                            )}


                                            {showFilter && item === "Servicer Name" && (
                                                <Grid item xs={2.4}>
                                                    <FormControl fullWidth >
                                                        <TextField
                                                            size="small"
                                                            id="ServicerName"
                                                            label="Servicer Name"
                                                            variant="outlined"
                                                            value={ServicerName}
                                                            onChange={(event) => {
                                                                setServicerName(event.target.value);

                                                            }}
                                                        />
                                                    </FormControl>

                                                </Grid>

                                            )}
                                            {showFilter && item === "Post Code" && (
                                                <Grid item xs={2.4}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            type='number'
                                                            size="small"
                                                            id="postCode"
                                                            label="postCode"
                                                            variant="outlined"
                                                            value={postCode ? postCode : ''}
                                                            onChange={(event) => {

                                                                setPostCode(event.target.value);

                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )}
                                            {showFilter && item === "Industry" && (
                                                <Grid item xs={2.4}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="industry-select-label">{item}</InputLabel>
                                                        <Select
                                                            labelId="industry-select-label"
                                                            id="industry-select"
                                                            value={selectedIndustry ? selectedIndustry?.industryName : ""}

                                                            label="Industry"
                                                            onChange={(event) => {
                                                                const selectedName = event.target.value;
                                                                const selected = IndustryList?.find(
                                                                    (country) => selectedName === country.industryName
                                                                );
                                                                setSelectedIndustry(selected);

                                                            }}
                                                        >
                                                            {IndustryList?.map((value) => (
                                                                <MenuItem
                                                                    key={value.industryId}
                                                                    value={value.industryName}
                                                                    sx={{ ...StyledScrollbar, }}
                                                                >
                                                                    {value.industryName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            {servicer && servicer?.length > 0 &&
                <p style={{ fontSize: '12px', minWidth: '100px', marginTop: "20px" }}>
                    {`${totalEntries} search result`}
                </p>
            }

            <div className="servicerContainer" style={{ marginTop: "5px", position: "relative" }}>
                {/* {servicer && servicer.length > 0 && <p >{`${totalEntries} search result`}
                </p>} */}
                {servicer ? <>
                    {
                        servicer && servicer?.length > 0 ? <>
                            <TableContainer component={Paper} elevation={3} sx={{ minHeight: "25rem", ...StyledScrollbar }}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead sx={{
                                        "&.MuiTableHead-root .MuiTableCell-root": {
                                            paddingTop: "10px", paddingBottom: "10px"
                                        }
                                    }}>
                                        <TableRow className='header'>
                                            <TableCell >Servicer Name</TableCell>
                                            <TableCell >Account Number</TableCell>
                                            <TableCell >Type</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell >Phone</TableCell>
                                            <TableCell >Status</TableCell>
                                            <TableCell >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {servicer?.map((row) => (
                                            <TableRow key={row.id || row.servicerId} >
                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >
                                                    {row.businessName || row.serviceProvider}
                                                </TableCell>
                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >{row.accountNumber || row.servicerAccountId}</TableCell>

                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >{row.servicerTypeName || row.servicerType}</TableCell>
                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >{row.primaryEmail || ""}</TableCell>
                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >{row.primaryPhone || ""}</TableCell>
                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >{row.statusName}</TableCell>
                                                <TableCell sx={{ paddingTop: "8px", paddingBottom: "8px" }} >
                                                    {/* <Edit></Edit>  */}
                                                    <IconButton onClick={() => view(row)}>
                                                        <Find ></Find>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </> : <p style={{ justifyContent: 'center' }}> No Data Found</p>
                    }
                </> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}><CircularProgress /></div>
                }

            </div>
            <Grid container style={{ margin: "0.9rem 0rem 2rem 0rem", justifyContent: "flex-end" }}>
                <Stack spacing={2}>
                    <Pagination count={totalPages} page={page} variant="outlined" shape="rounded" onChange={handlePageChange} />
                </Stack>
            </Grid>

        </div >
    )
}
export default Servicer;





















