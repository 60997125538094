import { Button, Grid, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import onPointLogo from "../../assets/SeviceAvengerIcon.svg"
import pageNotFoundImg from "../../assets/access-denied-image.svg"



export default function AccessDenied() {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate("/")
        window.localStorage.clear()
    }
    const flexClass = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }

    return (
        <Grid sx={{ width: "100%", }}>
            <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "3rem 0rem 0rem 4rem" }}>
                <img src={onPointLogo} alt='icon' />
            </Grid>
            <Grid sx={{ ...flexClass }}>
                <img src={pageNotFoundImg} style={{ height: '30%', width: '30%' }} alt='icon' />
                <Typography sx={{ fontSize: "30px", fontWeight: 900, color: "#1e384b", marginTop: "2rem" }}>Unauthorized Access !</Typography>
                {/* <Typography sx={{ fontSize: "20px", color: "#1e384b" }}>Something Went Wrong</Typography> */}
                {/* <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}> We are facing an unexpected error. You may also</Typography>
                <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>refresh  the page or try again later.</Typography> */}
                <Button
                    variant="contained"
                    onClick={handleNavigate}
                    sx={{ textTransform: "none", marginTop: "2rem", padding: "0.5rem 1rem" }}
                >
                    Back to Login
                </Button>

            </Grid>

        </Grid>
        // <div style={{ width: "100%", height: "100%" }}>
        //     <Paper elevation={3} sx={{ height: "20vh", width: "60%", margin: "auto", marginTop: "8rem", padding: "2em" }}>

        //         <Grid className='text_align' style={{ marginTop: "2rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} elevation={4}>
        //             <Typography variant='h4' style={{ fontSize: "25px", fontWeight: "600" }}>Access Denied</Typography>

        //             <Button variant="contained" sx={{ marginTop: "2rem", textTransform: "none" }} onClick={handleNavigate}>Back to Login</Button>
        //         </Grid>
        //     </Paper>
        // </div>
    )
}
