
export const DispatchSimulator = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Dispatch simulator",
    route: "/DispatchSimulator",
    isActive: true,
  }

];

export const RankingParameter = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Display Ranking",
    route: "/DisplayRanking",
    isActive: true,
  },
  {
    name: "Set Ranking Parameter",
    route: "/RankingParameters",
    isActive: true,
  },
];

export const Authorization = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Authorization",
    route: "/authorization",
    isActive: true,
  },
];
export const DispalyRanking = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Set Ranking Parameter",
    route: "/DisplayRanking",
    isActive: true,
  },
];


export const serviceOrder = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Service Order",
    route: "/ServiceOrder",
    isActive: true,
  },
];
export const ServiceOrderDetail = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Service Order",
    route: "/ServiceOrder",
    isActive: true,
  },
  {
    name: "Details",
    route: "/ServiceOrderDetails",
    isActive: false,
  },
];
export const UploadRanking = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },

  {
    name: "Set Ranking Parameters",
    route: "/UploadRanking",
    isActive: true,

  },
]
export const RateType = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Application Admin",
    //route: "/ApplicationAdmin",
    isActive: false,
  },
  {
    name: "Add New Rate Type",
    route: "/ApplicationAdmin/AddNewRateType",
    isActive: true,
  }
]
export const RateCard = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Application Admin",
    // route: "/ApplicationAdmin",
    isActive: false,
  },
  {
    name: "Available Rate Card",
    route: "/ApplicationAdmin/AvailableRateCard",
    isActive: true,
  }
]
export const Industry = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Application Admin",
    // route: "/ApplicationAdmin",
    isActive: false,
  },
  {
    name: "Industries",
    route: "/ApplicationAdmin/Industries",
    isActive: true,

  }
]
export const AddNewServicer = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {

  }
]
export const DispatchSimulatorDetailed = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Service Delivery",
    isActive: false,
  },
  {
    name: "Dispatch simulator",
    route: "/DispatchSimulator",
    isActive: true,
  },
  {
    name: "Details",
    route: "/detail/:id",
    isActive: true,
    isId: true
  }
];
export const AddNewRateCards = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Application Admin",
    // route: "/ApplicationAdmin",
    isActive: false,
  },
  {
    name: "Available Rate Card",
    route: "/ApplicationAdmin/AvailableRateCard",
    isActive: true,
  },
  {
    name: "AddNewRateCard",
    route: "/ApplicationAdmin/AddNewRateCard",
    isActive: true,
  },

]
export const ViewNewRateCard = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Application Admin",
    // route: "/ApplicationAdmin",
    isActive: false,
  },
  {
    name: "Available Rate Card",
    route: "/ApplicationAdmin/AvailableRateCard",
    isActive: true,
  },

  {
    name: "ViewNewRateCard",
    route: "/ApplicationAdmin/ViewNewRateCard/:id",
    isActive: true,
    isId: true
  }
]

export const PreviewRateCardRoute = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Application Admin",
    // route: "/ApplicationAdmin",
    isActive: false,
  },
  {
    name: "Available Rate Card",
    route: "/ApplicationAdmin/AvailableRateCard",
    isActive: true,
  },
  {
    name: "View Rate Card",
    route: "/ApplicationAdmin/PreviewRateCard/:id",
    isActive: true,
    isId: true

  },
]

export const ReportingClaim = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Reporting",
    isActive: false,
  },
  {
    name: "Claim",
    route: "/Reporting",
    isActive: true,
  }

];

export const ReportingServiceOrderReport = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Reporting",
    isActive: false,
  },
  {
    name: "Dispatch",
    isActive: false,
  },
  {
    name: "Service Order",
    route: "/Reporting",
    isActive: true,
  }

];

export const ReportingServicers = [
  {
    name: "home",
    route: "/Dashboard",
    isActive: true,
  },
  {
    name: "Reporting",
    isActive: false,
  },
  {
    name: "Network",
    isActive: false,
  },
  {
    name: "Servicers",
    route: "/Reporting",
    isActive: true,
  }

];