import { useState } from "react";
import NavItem from "./NavItem";
import "./SubNav.scss";

const SubNav = ({ items }) => {
  const [isActive, setIsActive] = useState(true);

  const handleContainerClick = (items) => {
    if (items[0].title === "Claim") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  return (
    <ul className="subNav" onClick={() => handleContainerClick(items)}>
      {isActive &&
        items?.map((item, key) => (
          <div className={`subNav-div`} key={item?.title}>
            <NavItem item={item} />
          </div>
        ))}
    </ul>
  );
};

export default SubNav;
