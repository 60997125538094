import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { getRequest } from "../../axios/axios";
import { useSelector } from "react-redux";

const ServiceName = () => {
  const servicerId = sessionStorage.getItem("servicerId");
  const statusFlg = useSelector((state) => {
    return state?.accountDetail?.status;
  });
  const getServicerNameFromRedux = useSelector((state) => {
    return state?.accountDetail?.ServicerName;
  });
  const getServicerFromLocalStorage = localStorage?.getItem("servicerName");
  const getServicerAccountNumberFromRedux = useSelector((state) => {
    return state?.accountDetail?.ServicerAccountNumber;
  });
  const getAccountDetails = async () => {
    let response;
    if (statusFlg == "Set up for Dispatch") {
      response = await getRequest(
        `/api/getServicerAccount?servicerId=${servicerId}`,
        "sdProvider"
      );
    } else if (statusFlg == "Inactive Servicer") {
      response = await getRequest(
        `/api/getServicerAccount?servicerId=${servicerId}`,
        "sdProvider"
      );
    } else {
      response = await getRequest(
        `/api/getServicerAccount?servicerId=${servicerId}`,
        "sdProvider"
      );
    }
    if (response?.data?.isSuccess) {
      window.localStorage.setItem("isdCode", response?.data?.data?.isdCode);
      window.localStorage.setItem(
        "phoneRegionCode",
        response?.data?.data?.phoneRegionCode
      );
      window.localStorage.setItem(
        "servicerName",
        response?.data?.data?.accountData?.account?.businessName
      );
      window.sessionStorage.setItem(
        "servicerId",
        response?.data?.data?.servicerId
      );
    }
  };

  useEffect(() => {
    if (servicerId) {
      getAccountDetails();
    }
  }, []);

  return (
    <>
      <Typography sx={{ color: "#1E384B", fontSize: "14px" }}>
        {getServicerNameFromRedux
          ? `${getServicerNameFromRedux ? getServicerNameFromRedux : ""}  ${
              getServicerAccountNumberFromRedux
                ? `${"(" + getServicerAccountNumberFromRedux + ")"}`
                : null
            }`
          : getServicerFromLocalStorage
          ? getServicerFromLocalStorage
          : ""}
      </Typography>
    </>
  );
};
export default ServiceName;
