import { useState, useEffect } from 'react';
import { menuItems } from './MenuItems';
import NavItem from './NavItem';
import './Navbar.scss';
import { toast } from 'react-toastify';
import { getRequest } from '../../axios/axios';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowW from '../../assets/NavBar/arrowW.svg';
import ArrowB from '../../assets/NavBar/arrowB.svg';
import ServiceOrder1B from '../../assets/NavBar/Active/serviceOrderB.svg';
import ServiceOrder1W from '../../assets/NavBar/onHover/serviceOrderW.svg';
import { saveIsReportingRender, saveIsMenuData } from './MenuItemSlice';
import ClaimW from '../../assets/NavBar/onHover/claimW.svg';
import ClaimB from '../../assets/NavBar/Active/ClaimIconB.svg';
import dispatchW from '../../assets/NavBar/onHover/dispatchIconW.svg';
import dispatchB from '../../assets/NavBar/Active/dispatchB.svg';
import networkW from '../../assets/NavBar/onHover/networkIconW.svg';
import networkB from '../../assets/NavBar/Active/networkIconB.svg';

export default function Navbar() {
  const [receiveMenu, setReceiveMenu] = useState(menuItems);
  const [getReportAreaData, setGetReportAreaData] = useState([]);
  const [menuIcon, setMenuIcon] = useState(false);
  const [stopRenderAgain, setStopRender] = useState(true);
  const [renderPause, setRenderPause] = useState(true);
  const dispatch = useDispatch();

  const getReportArea = async () => {
    const res = await getRequest(`/api/reportArea`, 'sdMaster');
    if (res?.data?.isSuccess) {
      if (res?.data?.data?.length) {
        setGetReportAreaData(res?.data?.data);
      } else {
        setGetReportAreaData(['']);
      }
      setStopRender(false);
    } else {
      toast.error(res?.data?.message);
    }
  };

  useEffect(() => {
    if (stopRenderAgain) {
      getReportArea();
    }
  }, [stopRenderAgain]); 

  useEffect(() => {
    if (renderPause && getReportAreaData.length > 0) {
      const newMenuItems = {
        title: 'Reporting',
        icon: <KeyboardArrowDownIcon />,
        subMenu: getReportAreaData.map((item) => ({
          title: item.reportAreaName ? item.reportAreaName : 'No Reports Found',
          ...(item?.reportAreaId === 1 && {
            icon: [<img src={ClaimB} alt="icon" key="active" />, <img src={ClaimW} alt="icon" key="hover" />],
          }),
          ...(item?.reportAreaId === 2 && {
            icon: [<img src={dispatchB} alt="icon" key="active" />, <img src={dispatchW} alt="icon" key="hover" />],
          }),
          ...(item?.reportAreaId === 3 && {
            icon: [<img src={networkB} alt="icon" key="active" />, <img src={networkW} alt="icon" key="hover" />],
          }),
          reportAreaId: item.reportAreaId,
          reportAreaCode: item.reportAreaCode,
          reportAreaName: item.reportAreaName,
          ArrowToOpen: item?.reportAreaName ? [<img src={ArrowW} alt="icon" />, <img src={ArrowB} alt="icon" />] : [],
          subNavMenu: item?.subNavMenu?.map((subItem) => ({
            title: subItem.reportMasterName,
            reportAreaId: subItem.reportAreaId,
            reportMasterCode: subItem.reportMasterCode,
            reportMasterName: subItem.reportMasterName,
            reportMasterId: subItem.reportMasterId,
            isActiveFlg: subItem.isActiveFlg,
            icon: [<img src={ServiceOrder1B} alt="icon" />, <img src={ServiceOrder1W} alt="icon" />],
            path: `/ReportingFilter`,
            item: subItem,
          })),
        })),
      };
      dispatch(saveIsMenuData(newMenuItems));
      setRenderPause(false);
      setReceiveMenu((prevItems) => {
        const newItems = [...prevItems];
        newItems.splice(menuItems.length - 1, 0, newMenuItems);
        return newItems;
      });
    }
  }, [getReportAreaData, renderPause, dispatch]);

  const removeDuplicates = (array) => {
    return array.reduce((acc, current) => {
      const data = acc.find((item) => item.title === current.title);
      if (!data) {
        acc.push(current);
      }
      return acc;
    }, []);
  };
  const uniqueMenuItems = removeDuplicates(receiveMenu);

  return (
    <div className="Navbar">
      <ul className={`nav-list${menuIcon ? 'active' : ''}`}>
        {uniqueMenuItems?.map((item, key) => (
          <NavItem key={key} item={item} />
        ))}
      </ul>

      <ul className="nav-list-desk">
        {uniqueMenuItems?.map((item, key) => (
          <NavItem key={key} item={item} />
        ))}
      </ul>
    </div>
  );
}
