import { Button, Grid, Typography } from "@mui/material";
import { StyledTextField } from "../../../components/StyledComponent/StyledComponent";
import { useState } from "react";
import LoginSlice from "../LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { postRequest } from "../../../axios/axios";
import {
  handleIsOtpIsValid,
  handleIsEmailVerifyed,
  handleForgetPassword,
  saveEmail,
  handleIsEmailValid,
} from "../LoginSlice";
import { toast } from "react-toastify";
import ForgetMailPassSchema from "./ForgetMailPassSchema";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const ForgetMailPass = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const getEmailFromRedux = useSelector((state) => {
    return state?.Login?.userDetail?.email;
  });
  let defaultValue = {
    email: "",
  };
  const handleVerifyEmail = async () => {
    if (email.length == 0) {
      return toast.error("Enter Your Email Address");
    }
    if (!formik.isValid) {
      return;
    }
    setIsDisabled(true);

    try {
      const reqBody = {
        email: `${email}`,
      };
      const res = await postRequest(`/api/forgot/password`, reqBody, "sdLogin");
      if (res.data.isSuccess) {
        setIsDisabled(false);
        if (res.data.data.isEmailExist && res.data.data.isPasswordExist) {
          dispatch(handleIsEmailValid(true));
        }
        if (res.data.data.isEmailExist && !res.data.data.isPasswordExist) {
          navigate("/");
          dispatch(handleIsEmailValid(true));
        }
        if (res?.data?.isSuccess && res?.data?.statusCode === 200) {
          dispatch(handleIsEmailValid(true));

          return toast.success(res?.data?.data?.message);
        }
      } else if (!res.data.isSuccess) {
        if (
          res?.data?.statusCode === 400 &&
          res?.data?.error === "User not found"
        ) {
          return toast.error(res?.data?.message);
        }
        toast.error(res?.data?.message);
        setIsDisabled(false);
      } else {
        setIsDisabled(false);
        toast.error(res.data.error);
      }
    } catch (error) {
      setIsDisabled(false);
      console.error("An error occurred:", error);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: defaultValue,
    validationSchema: ForgetMailPassSchema,
    onSubmit: handleVerifyEmail,
  });
  const dispatch = useDispatch();

  const handleOnClickOnReset = () => {
    handleVerifyEmail();
    // dispatch(handleIsOtpIsValid(true))
  };
  const handleEmailChange = (e) => {
    formik.setFieldValue("email", e.target.value);
    dispatch(saveEmail(e.target.value));
    setEmail(e.target.value);
    setIsDisabled(false);
  };
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem ",
          width: "88%",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h7"
              align="center"
              color="#040F3E"
              sx={{ margin: "0px 0px 10px 0px" }}
            >
              Forgot Password?
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              align="center"
              variant="caption"
              sx={{
                marginTop: "5px",
                color: "rgba(0, 0, 0, 0.6)",
                textTransform: "none",
              }}
            >
              Enter the email address associated{" "}
            </Typography>
            <Typography
              align="center"
              variant="caption"
              sx={{
                marginTop: "5px",
                color: "rgba(0, 0, 0, 0.6)",
                textTransform: "none",
              }}
            >
              with your account
            </Typography>
          </Grid>

          <Grid xs={12} item sm={12} sx={{ margin: "20px 0px" }}>
            <StyledTextField
              size="small"
              fullWidth
              name="email"
              label="Your Email Address"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={handleEmailChange}
              onClick={(e) => setEmail(e.target.value)}
              error={Boolean(formik.touched.email && formik.errors.email)}
            />
            {Boolean(formik.touched.email && formik.errors.email) && (
              <Typography variant="body2" color="error">
                {formik.errors.email}
              </Typography>
            )}
          </Grid>

          <Grid item sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleOnClickOnReset}
              disabled={isDisabled}
            >
              Reset Password
            </Button>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
export default ForgetMailPass;
