import { useState, useRef, useEffect } from "react";
import "./NavItem.scss";
import { useLocation, useNavigate } from "react-router-dom";
import SubNav from "./SubNav";
import {
  changeGlobalViewMode,
  saveServicerName,
} from "../../pages/AccountDetails/accountDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import {
  saveSelectedCountryId,
  saveIsTableLoader,
  saveIsHistoryTableLoader,
  saveIsClickedSubMenuEvent,
  saveIsTableData,
  saveIsHistoryDataCall,
  saveIsTableDataCall,
  saveIsReportNameId,
  saveIsHistoryTableData,
  saveIsFilterData,
  saveIsCloseSubmenu,
  saveIsClaim,
  saveIsSubMenu,
  saveIsShowDownloadBookMark,
  saveIsFilterSearch,
} from "./MenuItemSlice";
import { getRequest } from "../../axios/axios";
import { toast } from "react-toastify";
import axios from "axios";

const NavItem = ({ item, key }) => {
  const [openSubmenu, setOpenSubmenu] = useState();
  const [isActive, setIsActive] = useState(false);
  const [activeImg, setHoveredImg] = useState(false);
  const [subNavActiveImg, setSubNavActiveImg] = useState(false);

  const getCloseSubmenuFromRedux = useSelector((state) => {
    return state?.ReportingDetail?.closeSubmenu;
  });

  const [keyCLockToken, setkeyCloakLoginToken] = useState(
    localStorage?.getItem("keyCloakLoginToken")
  );
  useEffect(() => {
    setkeyCloakLoginToken(localStorage?.getItem("keyCloakLoginToken"));
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const navItemRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navItemRef.current && !navItemRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getFilterReportMasterInfo = async (reportMasterName) => {
    const res = await getRequest(
      `/api/reportMaster/info?reportMasterCode=${reportMasterName}`,
      "sdMaster"
    );
    if (res?.data?.isSuccess) {
      dispatch(saveIsFilterData(res?.data?.data));
    } else {
      let error = res?.data?.message;
      dispatch(saveIsFilterData([]));
      toast.error(error);
    }
  };

  const getReportsReportMasterId = async (reportMasterId) => {
    let userId = localStorage?.getItem("userId");

    dispatch(saveIsTableDataCall(false));
    dispatch(saveIsShowDownloadBookMark(false));

    dispatch(saveIsHistoryDataCall(true));
    const res = await getRequest(
      `/api/getReports?reportMasterId=${reportMasterId}&userId=${userId}&page=0&size=10`,
      "sdMaster"
    );
    if (res?.data?.isSuccess) {
      dispatch(saveIsHistoryTableData(res?.data));
      dispatch(saveIsHistoryTableLoader(true));

      dispatch(saveIsTableData([]));
    } else {
      dispatch(saveIsHistoryTableData([]));
      dispatch(saveIsHistoryTableLoader(true));

      toast.error(res?.data?.message);
    }
  };

  const validateToken = async () => {
    const response = await getRequest("/api/token/validate", "sdLogin");
    if (response?.data?.isSuccess) {
      if (!!keyCLockToken) {
        window.open(
          `${process.env.REACT_APP_CLAIMS_URL}/dashboard?access_token=${keyCLockToken}`
        );
      }
    }
  };

  const handleMenuItems = (event, key) => {
    if (event.path) {
      sessionStorage.removeItem("myObject");
      sessionStorage.removeItem("ReportingEvent");
    }

    setIsActive(false);
    if (event.title === "Dispatch") {
      dispatch(saveIsSubMenu(true));
    }
    if (event.title === "Network") {
      dispatch(saveIsClaim(false));
      dispatch(saveIsSubMenu(true));
    }

    if (event.title === "File Claim") {
      validateToken();

      navigate(location.pathname);
    } else {
      navigate(event.path ? event.path : location.pathname);
    }
    if (event.title === "Add New Servicer") {
      dispatch(changeGlobalViewMode("post"));
      sessionStorage.removeItem("servicerId");
      sessionStorage.removeItem("servicerAccountId");
      sessionStorage.removeItem("isPostCoverageFileExit");
      sessionStorage.removeItem("servicerType");
      sessionStorage.removeItem("serviceLocationId");
    } else {
      dispatch(changeGlobalViewMode("notPost"));
      localStorage.removeItem("servicerName");
      dispatch(saveServicerName(""));
    }
  };

  const handleSubMenuItems = (event) => {
    navigate(event?.path);
    getFilterReportMasterInfo(event?.reportMasterCode);
    getReportsReportMasterId(event?.reportMasterId);
    dispatch(saveIsReportNameId(event?.reportMasterId));
    dispatch(saveIsClickedSubMenuEvent(event));
    const jsonString = JSON?.stringify(event);
    sessionStorage.setItem("ReportingEvent", jsonString);
    dispatch(saveIsCloseSubmenu(false));
    dispatch(saveIsTableLoader(false));
    dispatch(saveIsHistoryTableLoader(false));
    dispatch(saveIsFilterSearch([]));
    dispatch(saveSelectedCountryId(null));
  };

  // styling
  const styledMenuBtn = {
    zIndex: "10000",
    textTransform: "none",
  };
  const styledSubMenuBtn = {
    zIndex: "10000",
    textTransform: "none",
    minWidth: "12rem",
    padding: "15px 0px",
    backgroundColor: "white",
    BorderBottom: "none",
    boxShadow: "none",
    textAlign: "left",
    justifyConent: "start",
    "&.MuiButton-root:hover": {
      backgroundColor: "#02A0FC",
      color: "#FFF",
    },
  };
  const styledSubMenuUl = {
    paddingLeft: "0",
    paddingTop: "20px",
    paddingBottom: "20px",
    position: "absolute",
    // left: 217,
    marginLeft: "10.9rem",
    top: 0,
    // minHeight: "20vh",
    boxShadow: "0px 12px 15px 0px rgba(140,149,159,0.3)",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  };
  return (
    <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
      <li
        ref={navItemRef}
        className={`nav-item ${isActive ? "active" : ""}`}
        onMouseEnter={(e) => {
          setHoveredImg(true);
          setOpenSubmenu(true);
        }}
        onMouseLeave={(e) => {
          setHoveredImg(false);
          setOpenSubmenu(false);
        }}
      >
        {item?.subMenu ? (
          <div
            className={`sub-element`}
            style={{ zIndex: "10000" }}
            onClick={() => {
              setIsActive(!isActive);
              dispatch(saveIsCloseSubmenu(true));
            }}
          >
            {item?.title}
            {Array.isArray(item?.icon) === true
              ? activeImg
                ? item?.icon[1]
                : item?.icon[0]
              : item?.icon}
          </div>
        ) : (
          <>
            <Button
              disableTouchRipple
              sx={styledMenuBtn}
              className="sub-element"
              // onMouseEnter={(e) => { setSubNavActiveImg(true) }}
              onMouseLeave={(e) => {
                setSubNavActiveImg(false);
              }}
              onClick={(e) => {
                handleMenuItems(item, key);
                setOpenSubmenu(true);
              }}
            >
              {Array.isArray(item?.ArrowToOpen) === true
                ? activeImg
                  ? item?.ArrowToOpen[1]
                  : item?.ArrowToOpen[0]
                : item?.ArrowToOpen}
              <Button
                disableTouchRipple
                sx={styledMenuBtn}
                className={`${activeImg ? "link1" : "link"}`}
              >
                {item?.title}
              </Button>
              {openSubmenu && (
                <li
                  className={`nav-item ${isActive ? "active" : ""}`}
                  style={styledSubMenuUl}
                >
                  {item?.subNavMenu
                    ? item?.subNavMenu?.map((item) => (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSubMenuItems(item);
                          }}
                          disableTouchRipple
                          sx={{
                            ...styledSubMenuBtn,
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "1rem",
                            marginLeft: "15px",
                          }}
                          className={`${subNavActiveImg ? "link1" : "link"} `}
                        >
                          {Array.isArray(item?.icon) === true
                            ? subNavActiveImg
                              ? item?.icon[1]
                              : item?.icon[0]
                            : item?.icon[0]}
                          &nbsp;&nbsp;
                          <span style={{ padding: "12px 5px" }}>
                            {item?.title}
                          </span>
                        </Button>
                      ))
                    : null}
                </li>
              )}

              {Array.isArray(item?.icon) === true
                ? activeImg
                  ? item?.icon[1]
                  : item?.icon[0]
                : item?.icon}
            </Button>
          </>
        )}

        {getCloseSubmenuFromRedux && isActive && item.subMenu && (
          <div style={styledMenuBtn} className="subMenu-container">
            <SubNav items={item?.subMenu} />
          </div>
        )}
      </li>
      {/* ***************************Sub-menu******************************* */}
    </ul>
  );
};

export default NavItem;
