import { Grid, Paper, Typography } from "@mui/material";
import ServiceAvengerIcon from "../../assets/SeviceAvengerIcon.svg"
import "./Login.scss";
import SignIn from "./Components/SignIn";
import OTPConfirmation from "./Components/OTPConfirmation";
import SetPassword from "./Components/SetPassword";
import Confirmed from "./Components/Confirmed";
import { useEffect, useState } from "react";
import SignInWithPass from "../Login/Components/SignInWithPass"
import { useSelector } from "react-redux";
import LoginSlice from "./LoginSlice";
import ForgetMailPass from "./Components/ForgetMailPass";
import CheckIocn from "../../assets/bi_check.svg";
import ResetPassword from "./Components/ResetPassword";
const Login = () => {

  const [showSetPassword, setShowSetPassword] = useState(false)

  const [isPasswordCreated, setIsPasswordCreated] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [microsoftCred, setMicrosoftCred] = useState()

  const handleSetPasswordComponent = (value) => {
    setShowSetPassword(value)
  }
  const handleSetLoginComponent = (value) => {
    setIsPasswordCreated(value)
  }
  const handleUserEmail = (value) => {
    setUserEmail(value)
  }


  const isPasswordCreatedFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isPasswordCreated
  })

  const isEmailValidFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isEmailValid
  })

  const isEmailVerifyedFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isEmailVerifyed
  })

  const isEmailPassVerifyedFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isEmailPassVerifyed
  })
  const isOtpIsValidFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isOtpIsValid
  })
  const isForgetPasswordFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isForgetPassword
  })
  const isResetPasswordFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isResetPassword
  })
  const IsCallFromForgetPassFromRedux = useSelector((state) => {
    return state?.Login?.flag?.isCallFromForgetPass
  })
  return (
    <>

      <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", }}>

        <Grid className="main-login" container sx={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
          <Grid className="login-logo" item>
            <img src={ServiceAvengerIcon} alt="icon" height="40px" />
          </Grid>
        </Grid>
        {
          isOtpIsValidFromRedux && !isPasswordCreatedFromRedux && isResetPasswordFromRedux && !IsCallFromForgetPassFromRedux &&
          (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
              <img src={CheckIocn} alt="icon" />
              <Typography variant="caption" sx={{ marginTop: "5px", color: "rgba(0, 0, 0, 0.6)", textTransform: "none" }} >
                Your email address has been verified successfully.
              </Typography>
            </div>
          )

        }
        {
          isOtpIsValidFromRedux && !isPasswordCreatedFromRedux && isResetPasswordFromRedux && IsCallFromForgetPassFromRedux &&
          (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
              <img src={CheckIocn} alt="icon" />
              <Typography variant="caption" sx={{ marginTop: "5px", color: "rgba(0, 0, 0, 0.6)", textTransform: "none" }} >
                Your email address has been verified successfully.
              </Typography>
            </div>
          )

        }
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "-5px" }}>

          <Grid className="main-box" sm={6} xs={12} container component={Paper} elevation={0} sx={{
            "&.MuiGrid-root": {
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              padding: "10px 0px"
            }
          }}>
            <Grid className="box_shadow_responsive">
            {
              !showSetPassword && !isPasswordCreatedFromRedux && !isEmailValidFromRedux && !isForgetPasswordFromRedux && (
                <SignIn setMicrosoftCred={setMicrosoftCred} handleSetPasswordComponent={handleSetPasswordComponent} handleUserEmail={handleUserEmail} isPasswordCreated={isPasswordCreated} />
              )
            }
            {
              isEmailValidFromRedux && !isOtpIsValidFromRedux && !isPasswordCreatedFromRedux && (<OTPConfirmation />
              )

            }
            {
              isOtpIsValidFromRedux && !isPasswordCreatedFromRedux && isResetPasswordFromRedux && !IsCallFromForgetPassFromRedux && (
                <SetPassword userEmail={userEmail} handleSetLoginComponent={handleSetLoginComponent} />
              )
            }
            {
              isOtpIsValidFromRedux && !isPasswordCreatedFromRedux && isResetPasswordFromRedux && IsCallFromForgetPassFromRedux && (
                <ResetPassword userEmail={userEmail} handleSetLoginComponent={handleSetLoginComponent} />
              )
            }
            {
              isPasswordCreatedFromRedux && isEmailVerifyedFromRedux && isEmailValidFromRedux && (
                <SignIn />

              )
            }
            {
              isForgetPasswordFromRedux && !isOtpIsValidFromRedux && !isEmailValidFromRedux && (
                <ForgetMailPass />
              )
            }
            </Grid>
           
          </Grid>
        </div>

      </div>




    </>
  )

}
export default Login;