import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";
import * as React from "react";
import Header from "./components/Header/Header";
import HeaderSidebar from "./components/Header/HeaderSidebar";
import BreadCrumb from "./utils/BreadCrumb";
import { createTheme, Grid, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { useDynamicRoutes } from "./routes/index";
import Navbar from "./components/Navbar/Navbar";
import ServicerNavBar from "./components/ServicerNavBar/ServicerNavBar";
import Interceptor from "./components/InterceptorComponent";
import { useState } from "react";
import ErrorBoundary from "utils/ ErrorBoundary";
import AccessDenied from "pages/AccessDenied/AccessDenied";

const theme = createTheme({
  palette: {
    primary: {
      main: "#02A0FC", // Aqua color
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF", // White color for secondary
      contrastText: "#000000", // Black color for secondary text
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1600,
      xl: 1900,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            "&:hover": {
              "& fieldset": {
                borderColor: "#02A0FC",
                borderWidth: "2px",
              },
            },
            "&.Mui-focused": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {},
        colorPrimary: {
          "&.Mui-checked": {
            color: "#02A0FC",
          },
        },
        track: {
          opacity: 0.9,
          backgroundColor: "#AEB5BC",
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.9,
            backgroundColor: "#AEB5BC",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

const Layout = () => {
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const orgType = localStorage.getItem("organisationType");
  const location = useLocation();
  const showHeaders = location.pathname === "/";
  const environment = process.env.REACT_APP_ENV;

  const Display = () => {
    return environment && environment !== "prod";
  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <Interceptor />
      {Display() && <div className="env">{environment}</div>}
      {orgType === "opw" && (
        <>
          <Header />
          <Navbar />
          <div className="boxContainer" style={{ position: "relative" }}>
            <BreadCrumb />
            <Outlet />
          </div>
        </>
      )}
      {orgType === "servicer" && (
        <Grid
          sm={12}
          className="boxContainerServicer"
          style={{
            position: "absolute",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {!showHeaders && (
              <Grid
                className={
                  hamburgerMenu
                    ? "service_navbar_show servicer-navbar"
                    : "service_navbar_hide servicer-navbar"
                }
                sm={2.2}
                xs={12}
                item
                sx={{ background: "rgba(243, 246, 253, 1)" }}
              >
                <ServicerNavBar
                  hamburgerMenu={hamburgerMenu}
                  setHamburgerMenu={setHamburgerMenu}
                />
              </Grid>
            )}
            <Grid xs={12} sm={9.8} md={9.8} style={{ width: "100%" }}>
              {!showHeaders && (
                <div style={{ marginTop: "20px", boxSizing: "border-box" }}>
                  <HeaderSidebar setHamburgerMenu={setHamburgerMenu} />
                </div>
              )}
              <Grid
                xs={12}
                className="outlet_responsive"
                sm={12}
                sx={{ boxSizing: "border-box" }}
              >
                <div style={{ margin: "auto" }}>
                  <Outlet />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {orgType === "agent" && (
        <Grid
          sm={12}
          className="boxContainerServicer"
          style={{
            position: "absolute",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              marginTop: "0.4rem",
            }}
          >
            {/* <Header /> */}
            <AccessDenied />
          </Grid>
        </Grid>
      )}
      {!orgType && (
        <div className="boxContainer" style={{ position: "relative" }}>
          <Outlet />
        </div>
      )}
    </div>
  );
};

function App() {
  const routes = useDynamicRoutes();
  const routerAdmin = createBrowserRouter([
    {
      path: "/",
      element: (
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      ),

      children: routes,
    },
  ]);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider router={routerAdmin} />
    </ThemeProvider>
  );
}

export default App;
