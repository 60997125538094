import React, { useEffect, useState } from 'react'
import SearchBar from '../SearchBar/SearchBar'
import UserDetails from '../UserDetails/UserDetails'
import Navbar from '../Navbar/Navbar'
import "./Header.scss"
import ServicerName from "../Header/ServicerName"
import { useParams } from 'react-router-dom'


export default function Header() {
  const [isShow,setIsShow] = useState(true)

  useEffect(() => {
    const url = window.location.href; // Get the current URL
    if (url.includes("ApplicationAdmin")) {
      setIsShow(false)
    } else {
      setIsShow(true)
    }
  },[window.location.href]);

  const { id } = useParams()
  return (
    <div className='Header-Container' style={{ position: "relative" }}>
      <div className='Header'>
        <img src='/Logo.png' alt='logo' className='logo'></img>
        <div className='Search-User'>

          {(id && isShow) ? <ServicerName /> : null}
          <SearchBar />
          <UserDetails />
        </div>
      </div>
    </div>
  )
}
