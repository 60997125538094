import { Button, Grid, Typography } from "@mui/material";
import { StyledTextField } from "../../../components/StyledComponent/StyledComponent";
import { useEffect, useState } from "react";
import LoginSlice from "../LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { postRequest } from "../../../axios/axios";
import {
  handleIsOtpIsValid,
  handleIsEmailVerifyed,
  handleResetPassword,
  saveOtp,
} from "../LoginSlice";
import { toast } from "react-toastify";

const OTPConfirmation = () => {
  const [isOtp, setIsOtp] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isResendOtp, setResendOtp] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const getEmailFromRedux = useSelector((state) => {
    return state?.Login?.userDetail.email;
  });
  const dispatch = useDispatch();

  const handleVerify = async () => {
    if (isOtp.length == 0) {
      return toast.error("Enter Verification Code");
    }
    setIsDisabled(true);
    try {
      const reqBody = {
        email: getEmailFromRedux,
        otp: isOtp,
      };

      const res = await postRequest(`/api/otp/verify`, reqBody, "sdLogin");
      if (res.data.isSuccess) {
        setIsDisabled(false);

        if (res.data.data.isVerified) {
          dispatch(handleIsOtpIsValid(true));
          dispatch(handleIsEmailVerifyed(true));
          dispatch(handleResetPassword(true));
        }
      } else if (!res.data.isSuccess) {
        setIsDisabled(false);
        toast.error(res?.data?.message);
      } else {
        setIsDisabled(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setIsDisabled(false);
    }
  };
  const handleOnChange = (e) => {
    setIsOtp(e.target.value);
    dispatch(saveOtp(e.target.value));
    setIsDisabled(false);
  };
  const handleResendOTP = async () => {
    setIsOtp("");
    const reqBody = {
      email: getEmailFromRedux ? getEmailFromRedux : "",
    };
    const res = await postRequest(`/api/email/validate`, reqBody, "sdLogin");
    if (res.data.isSuccess) {
      setResendOtp(false);
      setResendTimer(30);
    }

    setResendOtp(false);
    setIsDisabled(false);

    // else if (!res.data.isSuccess) {
    //   setIsDisabled(false)
    //   toast.error("Invalid OTP");

    // }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isResendOtp && resendTimer > 0) {
        setResendTimer((prevTimer) => prevTimer - 1);
      } else if (resendTimer === 0) {
        setResendOtp(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isResendOtp, resendTimer]);

  return (
    <>
      <Grid
        sx={{ display: "flex", flexDirection: "column", padding: "1rem 2rem" }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h7"
            align="center"
            color="#040F3E"
            sx={{ margin: "0px 0px 10px 0px" }}
          >
            OTP Verification
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            align="center"
            variant="caption"
            sx={{
              marginTop: "5px",
              color: "rgba(0, 0, 0, 0.6)",
              textTransform: "none",
            }}
          >
            {" "}
            {`We have sent an OTP to email ${getEmailFromRedux}. Please enter the code to proceed`}
            .
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12} sx={{ margin: "20px 0px" }}>
          <StyledTextField
            size="small"
            fullWidth
            name="Enter Verification Code"
            value={isOtp}
            onChange={(e) => handleOnChange(e)}
            label="Enter Verification Code"
          />
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={handleVerify}
            sx={{ textTransform: "none" }}
            disabled={isDisabled}
          >
            Verify and Proceed
          </Button>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!isResendOtp ? (
            <Typography
              variant="caption"
              align="center"
              sx={{
                marginTop: "5px",
                color: "rgba(0, 0, 0, 0.6)",
                textTransform: "none",
                textAlign: "center",
              }}
            >
              {/* Resend OTP in 00:20. */}
              {/* {`Resend OTP in 00:${resendTimer < 10 ? `0${resendTimer}` : resendTimer}.`} */}
            </Typography>
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="caption"
                align="center"
                sx={{
                  marginTop: "5px",
                  color: "rgba(0, 0, 0, 0.6)",
                  textTransform: "none",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Don't receive an OTP?
                <Button
                  disableElevation
                  disableRipple
                  sx={{
                    color: " #02A0FC",
                    textTransform: "none",
                    cursor: "pointer",
                    "&.MuiButton-root:hover": {
                      backgroundColor: "#FFF",
                    },
                    padding: 0,
                    margin: 0,
                    marginLeft: "5px",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.textDecoration = "underline")
                  }
                  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                  onClick={handleResendOTP}
                >
                  Resend OTP?
                </Button>
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default OTPConfirmation;
