import { useEffect, useState, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, OutlinedInput, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Switch, Typography, Avatar } from '@mui/material'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyleSelect, StyledSelectDialog, StyledInputLabel, StyledTableHead, StyledTextFieldDialog, StyledTableBody, StyledTextField } from '../../components/StyledComponent/StyledComponent'
import CloseIcon from '@mui/icons-material/Close';
import Preview from './Preview'
import DataTable from './DataTable';
import { useFormik } from "formik";
import { FieldArray, Form, Formik, getIn } from "formik";
import { getRequest, postRequest, putRequest } from '../../axios/axios';
import DoughnutChart from '../../components/Doughnut/Doughnut';
import { createSchema, createTableSchema } from './schema';
import { toast } from 'react-toastify';
import EditICon from "../../assets/material-symbols_edit-outline.svg";
import './RankingParameters.scss'
import { StyledScrollbar } from '../../components/StyledComponent/StyledComponent';
import { useParams, useNavigate } from "react-router-dom";
import Rout from '../../utils/Rout';
import { RankingParameter } from '../../utils/Head';




export default function RankingParameters() {
    const [btnstyle, setBtnstyle] = useState(true);

    const fRef = useRef()
    const [open, setOpen] = useState(false)
    const backgroundColor = [
        "#8395a7",
        "#FEA47F",
        "#34495e",
        "#74b9ff",
        "#a29bfe",
        "#ff7675",
        "#ffeaa7",
        "#ffbe76",
        "#c7ecee",
        "#994D1C",
        "#cf6a87",
        "#574b90",
        "#BDC581",
        "#25CCF7",
        "#B33771",
        "#227093",
        "#778ca3",
        "#cd6133",
        "#c7ecee",
        "#0fb9b1",
        "#D98880",
        "#EB984E",
        "#3498DB",
        "#BB8FCE",
        "#F5B7B1",
        "#524EB7",
        "#D2C2D1",
        "#EFCFE3",
        "#E76D89",
        "#A37C82",
        "#E9D460"
    ]

    const [viewMode, setViewMode] = useState('post')
    const navigate = useNavigate()

    const initialValueDialog = {
        rankingParameterName: '',
        rankingParameterTotalRange: '',
        rankingParameterType: '',
        weightagePercentage: '',
        isActive: 1,
        isUpdated: -1
    }

    const [totalPercentage, setTotalPercentage] = useState(0)

    const [initialValueTable, setInitialValueTable] = useState([])

    const [selectionDropdown, setSelectionDropdown] = useState(null)

    const [radioValue, setRadioValue] = useState([{ id: 'YES', name: 'Yes' }, { id: 'NO', name: 'No' }]);

    const [focusedWeightage, setFocusedWeightAge] = useState(null)

    const [validationSchema, setValidationSchema] = useState(null)

    const [tableSchema, setTableScehma] = useState(null)

    const handleClose = () => {
        formik.resetForm()
        setOpen(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValueDialog,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (fRef.current?.values?.rankingParameterData.length > 0) {
                // setInitialValueTable(() => [...fRef.current?.values?.rankingParameterData, values])
                const data = {
                    ...values,
                    activeColor: backgroundColor[fRef.current?.values?.rankingParameterData?.length]
                }
                setInitialValueTable(() => [...fRef.current?.values?.rankingParameterData, data])
            }
            else {
                const data = {
                    ...values,
                    activeColor: backgroundColor[0]
                }
                // setInitialValueTable([values])
                setInitialValueTable([data])
            }
            setTotalPercentage((prevData) => {
                const prev = prevData
                const total = prev + Number(values?.weightagePercentage)
                return total
            })

            handleClose()
        },
    })

    const getRankingParameterList = async () => {
        const response = await getRequest("/api/getRankingParameterList", "sdRating")

        if (response && response.data?.isSuccess) {
            const newArray = response?.data?.data?.rankingParameterData?.map((item, index) => {
                return { ...item, activeColor: backgroundColor[index] }
            })
            // setInitialValueTable(response?.data?.data?.rankingParameterData)
            setInitialValueTable(newArray)
            setTotalPercentage(response?.data?.data?.totalWeightagePercentage)
            if (response?.data?.data?.rankingParameterData.length > 0) {
                setViewMode('put')
            }
            else {
                setViewMode('post')

            }

        }
    }

    const getRankingParameterTypeList = async () => {
        const response = await getRequest("/api/getRankingTypeList", "sdRating")

        if (response && response.data?.isSuccess) {
            setSelectionDropdown(response.data?.items)

            // const binary = response.data?.items.filter((item)=>item.rankingTypeName==="Binary")
            // setRadioValue({minValue:binary[0]?.minValue,maxValue:binary[0]?.maxValue})
        }
    }

    useEffect(() => {
        getRankingParameterList()
        getRankingParameterTypeList()
    }, [])

    useEffect(() => {
        if (!selectionDropdown) return

        const schema = createSchema(selectionDropdown)
        const schemaTable = createTableSchema(selectionDropdown)
        setValidationSchema(schema)
        setTableScehma(schemaTable)
    }, [selectionDropdown])

    const handleChangeSwitch = (arrayHelpers, index, event, rankingItem) => {
        arrayHelpers?.form?.setFieldValue(`rankingParameterData[${index}].isActive`, event.target.checked ? 1 : 0)
        if (event.target.checked) {
            const total = totalPercentage + Number(rankingItem?.weightagePercentage)
            setTotalPercentage(total)
        }
        else {
            const total = totalPercentage - Number(rankingItem?.weightagePercentage)
            setTotalPercentage(total)
        }

    }

    return (
        <>
            <Rout arr={RankingParameter} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                <h2 className="Heading-text" style={{ textTransform: "Uppercase" }}> Set Ranking Parameters</h2>
                <div style={{ width: "20rem", display: "flex", justifyContent: "end" }}>
                    {
                        viewMode !== 'get' ? <Button size='small' type='submit' variant='contained' sx={{ padding: "8px 15px", margin: "0rem 1rem", textTransform: "capitalize" }} disableRipple onClick={() => setOpen(true)}>Add New</Button> : null
                    }
                    {
                        initialValueTable?.length > 0 && viewMode !== 'get' ? <Button size='small' type='submit' variant='contained' sx={{ padding: "8px 10px", marginRight: "1rem", textTransform: "capitalize" }} disableRipple
                            onClick={() => {
                                if (totalPercentage === 100) {
                                    fRef.current?.submitForm()
                                }
                                else {
                                    toast.error("Please Make Sure The Total Weightage Is 100%")
                                }
                            }}>Save</Button> : null
                    }
                    {/* {
                        viewMode !== "post" ? <Button className={btnstyle === true ? "btn_styling" : "btn_styling_selected"} size='small' type='submit' variant='outlined' sx={{
                            padding: "8px 10px", textTransform: "capitalize", border: "2px solid #02A0FC", "&:hover": {
                                border: "2px solid #02A0FC"
                            }
                        }} disableRipple

                            onClick={() => {
                                btnstyle === true && setBtnstyle((prev) => !prev)
                                setViewMode("put")
                            }}

                        >
                            <div
                                style={{ display: "flex", alignItems: "center" }}>
                                <img src={EditICon} alt='icon' />
                                Edit
                            </div>
                        </Button> : null
                    } */}
                </div>
            </div>
            <Dialog
                maxWidth='xs'

                PaperProps={{
                    sx: {
                        borderRadius: "15px", width: "25rem", overflow: "hidden!important",
                        "& .MuiDialogContent-root": {
                            overflow: "hidden!important"
                        }
                    }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle sx={{ m: 0, p: 2, alignSelf: 'center' }} id="customized-dialog-title">New Ranking Parameter</DialogTitle>
                <IconButton
                    disableRipple
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        // color: (theme) => theme.palette.grey[500],                                        
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px', marginTop: '5px' }}>
                    <StyledTextFieldDialog size="small" fullWidth label="Weightage %" value={formik.values?.weightagePercentage} name='weightagePercentage'
                        onChange={(event) => {
                            formik.setFieldValue('weightagePercentage', event.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched?.weightagePercentage && formik.errors?.weightagePercentage)}
                    />

                    {Boolean(
                        formik.touched?.weightagePercentage &&
                        formik.errors?.weightagePercentage
                    ) && (
                            <Typography variant="subtitle2" color="error">
                                {formik.errors?.weightagePercentage}
                            </Typography>
                        )}

                    <StyledTextFieldDialog size="small" fullWidth label="Ranking Parameter" value={formik.values?.rankingParameterName} name='rankingParameterName'
                        onChange={(event) => {
                            formik.setFieldValue('rankingParameterName', event.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                            formik.touched?.rankingParameterName &&
                            formik.errors?.rankingParameterName
                        )}
                    />

                    {Boolean(
                        formik.touched?.rankingParameterName &&
                        formik.errors?.rankingParameterName
                    ) && (
                            <Typography variant="subtitle2" color="error">
                                {formik.errors?.rankingParameterName}
                            </Typography>
                        )}

                    <FormControl fullWidth size="small">
                        <StyledInputLabel>Ranking Type</StyledInputLabel>
                        <StyledSelectDialog name="rankingParameterType" value={formik?.values?.rankingParameterType}
                            onChange={(event) => {
                                formik.setFieldValue('rankingParameterType', event.target.value)
                                if (event.target.value === "Binary") {
                                    // formik.setFieldValue('rankingParameterTotalRange',radioValue?.maxValue)
                                    // formik.setFieldValue('rankingParameterTotalRange', 'YES')
                                    formik.setFieldValue('rankingParameterTotalRange', '100')
                                }
                            }}
                            onBlur={formik.handleBlur}
                            fullWidth
                            input={<OutlinedInput label="Ranking Type" />}
                            error={Boolean(
                                formik.touched?.rankingParameterType &&
                                formik.errors?.rankingParameterType
                            )}
                        >
                            {selectionDropdown?.map((selection) => (
                                <MenuItem
                                    key={selection.rankingTypeId}
                                    value={selection.rankingTypeName}
                                >
                                    {selection.rankingTypeName}
                                </MenuItem>
                            ))}
                        </StyledSelectDialog>
                    </FormControl>

                    {Boolean(
                        formik.touched?.rankingParameterType &&
                        formik.errors?.rankingParameterType
                    ) && (
                            <Typography variant="subtitle2" color="error">
                                {formik.errors?.rankingParameterType}
                            </Typography>
                        )}

                    {
                        formik.values?.rankingParameterType === "Binary" ?
                            // <FormControl sx={{ alignSelf: 'flex-start', marginLeft: '5px' }}>
                            //     <FormLabel id="demo-controlled-radio-buttons-group">Value</FormLabel>
                            //     <RadioGroup
                            //         sx={{ flexDirection: 'row' }}
                            //         aria-labelledby="demo-controlled-radio-buttons-group"
                            //         name="controlled-radio-buttons-group"
                            //         // value={radioValue?.maxValue?radioValue?.maxValue:0}
                            //         value={formik?.values?.rankingParameterTotalRange}
                            //         onChange={(event) => {
                            //             formik.setFieldValue('rankingParameterTotalRange', event.target.value)
                            //         }}
                            //     >
                            //         <FormControlLabel value={'YES'} control={<Radio />} label="Yes" />
                            //         <FormControlLabel value={'NO'} control={<Radio />} label="No" />
                            //     </RadioGroup>
                            // </FormControl>
                            <></>
                            :
                            <>
                                <StyledTextFieldDialog size="small" fullWidth label="Maximum Points" value={formik.values?.rankingParameterTotalRange} name='rankingParameterTotalRange'
                                    onChange={(event) => {
                                        formik.setFieldValue('rankingParameterTotalRange', event.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(
                                        formik.touched?.rankingParameterTotalRange &&
                                        formik.errors?.rankingParameterTotalRange
                                    )}
                                    disabled={formik.values?.rankingParameterType ? false : true}
                                />

                                {Boolean(
                                    formik.touched?.rankingParameterTotalRange &&
                                    formik.errors?.rankingParameterTotalRange
                                ) && (
                                        <Typography variant="subtitle2" color="error">
                                            {formik.errors?.rankingParameterTotalRange}
                                        </Typography>
                                    )}
                            </>
                    }
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'end', mb: 2, gap: 2 }}>
                    <Button sx={{ boxShadow: 2, paddingX: 3, textTransform: "none", padding: "8px 12px", color: "black" }} autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button sx={{ boxShadow: 2, paddingX: 3, textTransform: "none", padding: "8px 12px", marginRight: "1rem" }} autoFocus variant="contained" onClick={() => formik?.submitForm()}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            {
                initialValueTable?.length > 0 ?
                    <Paper className='parent-paper' elevation={3} >

                        <Formik
                            initialValues={{
                                rankingParameterData: initialValueTable,
                            }}
                            enableReinitialize={true}
                            validationSchema={tableSchema}
                            onSubmit={(values) => {
                                if (viewMode === 'post') {
                                    const createRankingParameters = async () => {
                                        const response = await postRequest('/api/rankingParameter', { rankingParameters: values?.rankingParameterData }, 'sdRating')

                                        if (response && response.data?.isSuccess) {
                                            toast.success("Ranking Parameters Created Successfully")
                                            // getRankingParameterList()
                                            // getRankingParameterTypeList()
                                            // setViewMode('get')
                                            navigate('/DisplayRanking')
                                        }
                                    }
                                    createRankingParameters()
                                }
                                else {
                                    const updateRankingParameters = async () => {
                                        const response = await putRequest('/api/updateRankingParameter', { rankingParameters: values?.rankingParameterData }, 'sdRating')

                                        if (response && response.data?.isSuccess) {
                                            toast.success("Ranking Parameters Updated Successfully")
                                            navigate('/DisplayRanking')
                                            // getRankingParameterList()
                                            // getRankingParameterTypeList()
                                            // setViewMode('get')
                                            // setBtnstyle(true)
                                        }
                                        else {
                                            toast.error(response.data.message)
                                        }
                                    }
                                    updateRankingParameters()
                                }
                            }}
                            innerRef={(formikActions) => {
                                // if(formikActions?.touched&&Object.keys(formikActions?.touched)?.length>0){
                                //   setValues(formikActions?.values)
                                // }
                                return fRef.current = formikActions
                            }
                            }
                        >
                            {({
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                isValid,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                            }) => (
                                <Form noValidate autoComplete="off">
                                    <fieldset style={{ border: "none", position: "relative", zIndex: "0" }}>
                                        <div className='main-class'>
                                            <div className='left-section' style={{ width: '400px', height: '400px', position: 'static' }}>
                                                <DoughnutChart chartData={values?.rankingParameterData} />
                                                <div className='doughnut-text'>
                                                    <span>{totalPercentage}%</span>
                                                    <span>Total Weightage</span>
                                                </div>
                                            </div>

                                            <div className='right-section'>
                                                {
                                                    initialValueTable.length > 0 ? (
                                                        <TableContainer className='table-styling' component={Paper} sx={{ ...StyledScrollbar }}>
                                                            <Table stickyHeader
                                                            >
                                                                <StyledTableHead sx={{
                                                                    "& .MuiTableCell-root": {
                                                                        // backgroundColor:"#233849"
                                                                        backgroundColor: "#F2F4FF",
                                                                        paddingTop: "2px",
                                                                        paddingBottom: "2px"
                                                                    }
                                                                }}>
                                                                    <TableRow sx={{ textAlign: "center" }}>
                                                                        <TableCell className="table-styling-cell">Ranking Parameter</TableCell>
                                                                        <TableCell className="table-styling-cell" sx={{ whiteSpace: 'nowrap' }}>Weightage %</TableCell>
                                                                        <TableCell className="table-styling-cell">Ranking Type</TableCell>
                                                                        <TableCell sx={{ textAlign: "center" }}>Maximum Points/Value</TableCell>
                                                                        <TableCell>Inactive/Active</TableCell>
                                                                    </TableRow>
                                                                </StyledTableHead>
                                                                <StyledTableBody>
                                                                    <FieldArray
                                                                        name="rankingParameterData"
                                                                        render={(arrayHelpers) => (
                                                                            <>
                                                                                {
                                                                                    values?.rankingParameterData?.map((rankingItem, index) => {

                                                                                        const rankingParameterName = `rankingParameterData[${index}].rankingParameterName`;
                                                                                        const touchedRankingParameterName = getIn(touched, rankingParameterName)
                                                                                        const errorRankingParameterName = getIn(errors, rankingParameterName)

                                                                                        const weightagePercentage = `rankingParameterData[${index}].weightagePercentage`;
                                                                                        const touchedWeightagePercentage = getIn(touched, weightagePercentage)
                                                                                        const errorWeightagePercentage = getIn(errors, weightagePercentage)

                                                                                        const rankingParameterType = `rankingParameterData[${index}].rankingParameterType`;

                                                                                        const rankingParameterTotalRange = `rankingParameterData[${index}].rankingParameterTotalRange`;
                                                                                        const touchedRankingParameterTotalRange = getIn(touched, rankingParameterTotalRange)
                                                                                        const errorRankingParameterTotalRange = getIn(errors, rankingParameterTotalRange)

                                                                                        const activeSwitch = `rankingParameterData[${index}].isActive`;
                                                                                        const isUpdated = `rankingParameterData[${index}].isUpdated`
                                                                                        return (
                                                                                            <>
                                                                                                <TableRow key={rankingItem?.rankingParameterId ? rankingItem?.rankingParameterId : index}>
                                                                                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px', paddingTop: "4px", paddingBottom: "4px" }}>
                                                                                                        <div className='table-cell'
                                                                                                        // style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                                                                                                        >
                                                                                                            <span style={{ height: '20px', width: '20px', backgroundColor: rankingItem?.isActive ? rankingItem?.activeColor : '#FFF', display: 'flex', borderRadius: '5px' }} />
                                                                                                            {
                                                                                                                viewMode === 'get' ? rankingItem?.rankingParameterName :
                                                                                                                    <>
                                                                                                                        <StyledTextFieldDialog size="small" fullWidth value={rankingItem?.rankingParameterName} name={rankingParameterName}
                                                                                                                            onChange={(event) => {
                                                                                                                                setFieldValue(rankingParameterName, event.target.value)
                                                                                                                                viewMode === 'put' ? setFieldValue(isUpdated, 1) : setFieldValue(isUpdated, 0)
                                                                                                                            }}
                                                                                                                            onBlur={handleBlur}
                                                                                                                            error={Boolean(
                                                                                                                                touchedRankingParameterName && errorRankingParameterName
                                                                                                                            )}
                                                                                                                        />
                                                                                                                        {Boolean(
                                                                                                                            touchedRankingParameterName && errorRankingParameterName
                                                                                                                        ) && (
                                                                                                                                <Typography variant="subtitle2" color="error">
                                                                                                                                    {errorRankingParameterName}
                                                                                                                                </Typography>
                                                                                                                            )}
                                                                                                                    </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                    <TableCell sx={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                                                                        {
                                                                                                            viewMode === 'get' ? rankingItem?.weightagePercentage :
                                                                                                                <>
                                                                                                                    <StyledTextFieldDialog size="small" value={rankingItem?.weightagePercentage} name={weightagePercentage}
                                                                                                                        // onChange={(event)=>{
                                                                                                                        //     // if (!isNaN(event.target.value)||event.target.value==='') {
                                                                                                                        //     //     const difference = event.target.value===''?0:parseInt(event.target.value) - parseInt(rankingItem?.weightagePercentage)
                                                                                                                        //     //     setTotalPercentage(totalPercentage+difference)
                                                                                                                        //     //     setFieldValue(weightagePercentage,event.target.value)
                                                                                                                        //     // }
                                                                                                                        //     setFieldValue(weightagePercentage,event.target.value)

                                                                                                                        // }}
                                                                                                                        onFocus={() => {
                                                                                                                            // if (errorWeightagePercentage) {
                                                                                                                            //     // setFocusedWeightAge(null)
                                                                                                                            // }
                                                                                                                            // else {
                                                                                                                            setFocusedWeightAge(Number(rankingItem?.weightagePercentage))
                                                                                                                            // }
                                                                                                                        }}
                                                                                                                        onChange={
                                                                                                                            (event) => {
                                                                                                                                const numericRegex = /^[0-9]*$/;
                                                                                                                                if (numericRegex.test(event.target.value)) {
                                                                                                                                    setFieldValue(weightagePercentage, event.target.value)
                                                                                                                                    viewMode === 'put' ? setFieldValue(isUpdated, 1) : setFieldValue(isUpdated, 0)
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    event.preventDefault()
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        onBlur={
                                                                                                                            (event) => {
                                                                                                                                if (rankingItem.isActive) {
                                                                                                                                    if ((!isNaN(event.target.value)) && (Number(event.target.value) >= 0)) {

                                                                                                                                        const value = Number(event.target.value)
                                                                                                                                        const difference = value - focusedWeightage
                                                                                                                                        setTotalPercentage((prev) => prev + difference)
                                                                                                                                        setFocusedWeightAge(null)
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                handleBlur(event)
                                                                                                                            }
                                                                                                                        }
                                                                                                                        // onBlur={handleBlur}
                                                                                                                        error={Boolean(touchedWeightagePercentage && errorWeightagePercentage)}
                                                                                                                    />
                                                                                                                    {Boolean(
                                                                                                                        touchedWeightagePercentage && errorWeightagePercentage
                                                                                                                    ) && (
                                                                                                                            <Typography variant="subtitle2" color="error">
                                                                                                                                {errorWeightagePercentage}
                                                                                                                            </Typography>
                                                                                                                        )}
                                                                                                                </>
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                    <TableCell sx={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                                                                        {/* <FormControl fullWidth size="small">
                                                <StyledInputLabel>Ranking Type</StyledInputLabel>
                                                <StyleSelect name={rankingParameterType} value={rankingItem?.rankingParameterType}
                                                    onChange={(event)=>{
                                                    setFieldValue(rankingParameterType,event.target.value)
                                                    if(event.target.value==="Binary"){
                                                        setFieldValue('rankingParameterTotalRange','YES')
                                                    }
                                                }} 
                                                onBlur={handleBlur}
                                                fullWidth
                                                input={<OutlinedInput label="Ranking Type" />}
                                                >
                                                    {selectionDropdown?.map((selection) => (
                                                        <MenuItem
                                                            key={selection.rankingTypeId}
                                                            value={selection.rankingTypeName}
                                                        >
                                                    {selection.rankingTypeName}
                                                </MenuItem>
                                                 ))}
                                                </StyleSelect>
                                                </FormControl> */}
                                                                                                        {rankingItem?.rankingParameterType}
                                                                                                    </TableCell>

                                                                                                    <TableCell sx={{ textAlign: 'center', paddingTop: "4px", paddingBottom: "4px" }}>
                                                                                                        {
                                                                                                            viewMode === 'get' ? rankingItem?.rankingParameterTotalRange :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        rankingItem?.rankingParameterType === "Binary" ?
                                                                                                                            <FormControl fullWidth size="small">
                                                                                                                                {/* <StyledInputLabel>Ranking Type</StyledInputLabel> */}
                                                                                                                                {/* <StyledSelectDialog name={rankingParameterTotalRange} value={rankingItem?.rankingParameterTotalRange === 'YES' ? 'Yes' : 'No'}
                                                                                                                                    onChange={(event) => {
                                                                                                                                        setFieldValue(rankingParameterTotalRange, event.target.value === 'Yes' ? 'YES' : 'NO')
                                                                                                                                        viewMode === 'put' ? setFieldValue(isUpdated, 1) : setFieldValue(isUpdated, 0)
                                                                                                                                    }}
                                                                                                                                    onBlur={handleBlur}
                                                                                                                                    fullWidth
                                                                                                                                    sx={{ textAlign: "left" }}
                                                                                                                                // input={<OutlinedInput label="Ranking" />}
                                                                                                                                >
                                                                                                                                    {radioValue?.map((selection) => (
                                                                                                                                        <MenuItem
                                                                                                                                            key={selection.id}
                                                                                                                                            value={selection.name}
                                                                                                                                        >
                                                                                                                                            {selection.name}
                                                                                                                                        </MenuItem>
                                                                                                                                    ))}
                                                                                                                                </StyledSelectDialog> */}
                                                                                                                                <StyledTextField disabled={true} value={"YES/NO"}></StyledTextField>
                                                                                                                            </FormControl>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <StyledTextFieldDialog size="small" fullWidth value={rankingItem?.rankingParameterTotalRange} name={rankingParameterTotalRange}
                                                                                                                                    onChange={(event) => {
                                                                                                                                        const numericRegex = /^[0-9]*$/;
                                                                                                                                        if (numericRegex.test(event.target.value)) {
                                                                                                                                            setFieldValue(rankingParameterTotalRange, event.target.value)
                                                                                                                                            viewMode === 'put' ? setFieldValue(isUpdated, 1) : setFieldValue(isUpdated, 0)
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            event.preventDefault()
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    onBlur={handleBlur}
                                                                                                                                    error={Boolean(touchedRankingParameterTotalRange && errorRankingParameterTotalRange)}
                                                                                                                                />
                                                                                                                                {Boolean(
                                                                                                                                    touchedRankingParameterTotalRange && errorRankingParameterTotalRange
                                                                                                                                ) && (
                                                                                                                                        <Typography variant="subtitle2" color="error">
                                                                                                                                            {errorRankingParameterTotalRange}
                                                                                                                                        </Typography>
                                                                                                                                    )}
                                                                                                                            </>

                                                                                                                    }
                                                                                                                </>
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                    <TableCell sx={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                                                                        <Switch
                                                                                                            name={activeSwitch}
                                                                                                            checked={rankingItem?.isActive ? true : false}
                                                                                                            disabled={viewMode === 'get' ? true : false}
                                                                                                            onChange={(event) => {
                                                                                                                viewMode === 'put' ? setFieldValue(isUpdated, 1) : setFieldValue(isUpdated, 0)
                                                                                                                handleChangeSwitch(arrayHelpers, index, event, rankingItem)
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                                        />
                                                                                                    </TableCell>
                                                                                                </TableRow >
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </StyledTableBody>
                                                            </Table>
                                                        </TableContainer>) : null}
                                            </div>
                                        </div>
                                    </fieldset>
                                </Form>
                            )}
                        </Formik>

                    </Paper > : null
            }
        </>
    )
}
