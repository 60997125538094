
import ClaimsIconB from "../../assets/ServiceNavBarIcon/ClaimsIconB.svg";
import ClaimsIconW from "../../assets/ServiceNavBarIcon/ClaimsIconW.svg";

import MyJobsIconB from "../../assets/ServiceNavBarIcon/MyJobsIconB.svg";
import MyJobsIconW from "../../assets/ServiceNavBarIcon/MyJobsIconW.svg";

import CompanyProfileB from "../../assets/ServiceNavBarIcon/CompanyProfileB.svg";
import CompanyProfileW from "../../assets/ServiceNavBarIcon/CompanyProfileW.svg";
import PostDispatchEntitlementIconW from "../../assets/ServiceNavBarIcon/PostDispatchEntitlement.svg";
import PostDispatchEntitlementIconB from "../../assets/Vector.svg"
import EntitlementB from "../../assets/EntitlementB.svg"
import EntitlementW from "../../assets/EntitlementW.svg"
import { useSelector } from "react-redux";



let keyCloakLoginToken = localStorage?.getItem("keyCloakLoginToken");


let dashboardUrl = `${process.env.REACT_APP_CLAIMS_URL
  }/dashboard?access_token=${keyCloakLoginToken ? keyCloakLoginToken : ""}`;
export const MenuItems=()=>{
  const getFileClaimWithoutJobFromRedux = useSelector((state) => {
    return Number(state?.Login?.fileClaimWithoutJob)
  })
  const getEntitlementFileUploadFromRedux = useSelector((state) => {
    return Number(state?.Login?.entitlementFileUpload)
  })
  const getPostDispatchEntitlementFromRedux = useSelector((state) => {
    return Number(state?.Login?.postDispatchEntitlement)
  })
  return[
    {
      key: "1",
      title: "Company Profile",
      isActive: "false",
      path: "/CompanyProfile",
      Icon: [CompanyProfileB, CompanyProfileW],
      show: true
    },
    {
      key: "2",
      title: "Jobs",
      isActive: "false",
      path: "/Jobs",
      Icon: [MyJobsIconB, MyJobsIconW],
      show: true
    },
    {
      key: "3",
      title: "Claims",
      isActive: "false",
      Icon: [ClaimsIconB, ClaimsIconW],
      isUrl: dashboardUrl,
      show: true
    },
    {
      key: "4",
      title: "Post Dispatch Entitlement",
      isActive: "false",
      path: "/SubmittedJobs/list",
      Icon: [PostDispatchEntitlementIconB, PostDispatchEntitlementIconW],
      show: Number( getFileClaimWithoutJobFromRedux&&getPostDispatchEntitlementFromRedux)
    },
    {
      key: "5",
      title: "Entitlement File Upload",
      isActive: "false",
      Icon: [EntitlementB, EntitlementW],
      path: '/UploadedFiles',
      show: Number(getFileClaimWithoutJobFromRedux&&getEntitlementFileUploadFromRedux)
    },
  
  ];
}

