import { useState, useEffect } from 'react'
import { Box, Button, Paper, Table, TableCell, TableContainer, TableRow, Typography, Grid, Stack, Pagination, List, ListItem, ListItemText, CardActions, CardContent } from "@mui/material";
import { StyledScrollbar, StyledTableBody, StyledTableHead, StyledScrollbarHorrizontal } from '../../../components/StyledComponent/StyledComponent';
import { getRequest, putRequest } from '../../../axios/axios';
import { Link } from "react-router-dom";
import './Jobs.scss';
import RejectReasonAuthDialog from "./RejectReasonAuthDialog";
import { toast } from 'react-toastify';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import JobsFilters from './JobsFilters';

function Jobs() {
  const [tableData, setTableData] = useState([])
  const [onceClicked, setOnceClicked] = useState(false)
  const [offset, setOffset] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalPages, setTotalpages] = useState(0)
  const [page, setPage] = useState(1)
  const [openRejectAuthDialog, setOpenRejectAuthDialog] = useState(false)
  const [rejectAuthReasons, setRejectAuthReasons] = useState([])
  const [jobsOpen, SetJobsOpen] = useState([])

  const [value, setValue] = useState("")
  const [orderId, setOrderId] = useState("")
  const [storedQuery, setStoredQuery] = useState("")




  const getWindowSize = () => {
    const { innerWidth } = window;
    return innerWidth;
  }

  const getTableData = async (query = "") => {

    if (storedQuery) {

      // if (query.toString().includes("countryCode")) {
      //     getRequest(`/api/orderList/servicer/my?${query}&offset=${offset}`, "sdDispatch")
      //         .then(response => {
      //             // Handle the successful response
      //             if (response.data.isSuccess) {
      //                 setTotalEntries(response?.data?.data?.totalCount)
      //                 setTableData(response?.data?.data?.orderList);
      //             }
      //         })
      //         .catch(err => {
      //             toast.error(err)
      //         });
      // }
      const response = await getRequest(`/api/orderList/servicer/my?${query}&offset=${offset}`, "sdDispatch")
      let arr = [...jobsOpen]
      if (response?.data?.items) {
        setTotalEntries(response?.data?.total)
        for (let i = 0; i <= response?.data?.total; i++) {
          arr.push(false)
        }
        SetJobsOpen(arr)

        setTableData(response?.data?.items)

      }
      else if (response?.data?.message) {
        setTableData([])
        toast.error('Data Not Found ')
      }


    }

    else {
      const response = await getRequest(`/api/orderList/servicer/my?&offset=${offset}&limit=10`, "sdDispatch")
      let arr = [...jobsOpen]

      if (response && response.data.items) {
        setTotalEntries(response?.data?.total)
        for (let i = 0; i <= response?.data?.total; i++) {
          arr.push(false)
        }
        SetJobsOpen(arr)
        setTableData(response.data.items)
      }
    }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };


  })

  useEffect(() => {
    getTableData()
  }, [offset])

  const handleAcceptRejectOrder = async (value, orderId, comments, rejectReasonCode) => {
    setOnceClicked(true)
    let reqBody = {}
    if (value === "Accept") {
      reqBody = {
        "entityId": orderId,
        "status": value === "Accept" ? "In Progress" : "Done",
        "subStatus": value === "Accept" ? "Awaiting Appointment" : "Servicer Rejected"
      }
    }
    else {
      reqBody = {
        "entityId": orderId,
        "status": value === "Accept" ? "In Progress" : "Done",
        "subStatus": value === "Accept" ? "Awaiting Appointment" : "Servicer Rejected",
        "reasonNotes": comments,
        "reasonCode": rejectReasonCode

      }
    }

    const response = await putRequest(`/api/updateOrderStatus/${orderId}`,
      reqBody
      ,
      "sdDispatch"
    )

    if (response && response.data.isSuccess) {
      setOnceClicked(false)
      setOpenRejectAuthDialog(false)

      getTableData()
    }
    else {
      setOnceClicked(false)

    }
  }
  useEffect(() => {
    if (totalEntries % 10 === 0) {
      let calculatedTotalPages = totalEntries / 10;
      setTotalpages(calculatedTotalPages)
    } else {
      let calculatedTotalPages = Math.ceil(totalEntries / 10);
      setTotalpages(calculatedTotalPages)
    }

  }, [totalEntries])

  //handle page change in rank and dispatch list
  const handlePageChange = (event, value) => {
    setPage(value);
    const pageNumber = Number(value) - 1
    setOffset(pageNumber)
  }



  const handleOpenRejectAuthDialog = () => {
    setOpenRejectAuthDialog(true)

  }
  const handleCloseRejectAuthDialog = () => {
    setOpenRejectAuthDialog(false)
  }

  const handleReject = (value, orderId) => {
    setValue(value)
    setOrderId(orderId)
    handleOpenRejectAuthDialog()
  }
  //Reject Auth Reason//
  const getRejectAuthReaons = async () => {
    let obj = { "serviceNetworkID": "SP" }

    getRequest(`/api/getReasonList?providerType=OPW&reasonType=REJECT`, "sdIndustry")
      .then(response => {

        if (response.data.isSuccess) {

          setRejectAuthReasons(response?.data?.items)
        }
      })
      .catch(err => {
        toast.error(err)
      });
  }
  useEffect(() => {
    getRejectAuthReaons()
  }, [])

  const handleOpenJobsection = (index) => {
    let arr = [...jobsOpen]
    arr[index] = !arr[index]
    SetJobsOpen(arr)
  }

  const handleExpandAll = () => {
    let arr = [...jobsOpen]
    let alltruearr = arr.map((item) => {
      item = true
      return item
    })
    SetJobsOpen(alltruearr)
  }

  // jobs filters

  const onSearch = (query) => {
    if (storedQuery) {
      getTableData(storedQuery)

    }
    else {
      getTableData(query)

    }
  };

  useEffect(() => {

    if (storedQuery) {
      getTableData(storedQuery)
    }
    else {
      getTableData()

    }


  }, [offset])
  const refreshList = async () => {
    getTableData()
  }

  return (
    <>
      <Box
        mt={3}
        component={Grid}
        item
        md={12}
        display={{ xs: "none", sm: "block" }}>

        <Grid container md={12} sm={0} xs={0} lg={12} style={{ display: 'flex', justifyContent: "center", padding: "0rem 0rem" }} >
          <Grid container md={12} xl={12} ><JobsFilters refreshList={refreshList} onSearch={onSearch} disabledProp={false} setStoredQuery={setStoredQuery} setOffset={setOffset} offset={offset}></JobsFilters></Grid>
          <TableContainer TableContainer component={Paper} sx={{ ...StyledScrollbar, ...StyledScrollbarHorrizontal }} style={{ height: "45rem", overflowY: "scroll", marginBottom: "1rem", marginTop: "2rem" }}>
            <Table className='table-data-part' stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <StyledTableHead sx={{
                "&.MuiTableHead-root .MuiTableCell-root": {
                  paddingTop: "10px", paddingBottom: "10px", backgroundColor: "#F2F4FF"

                }
              }}>
                <TableRow className='table-row'>
                  <TableCell>Job Number</TableCell>
                  <TableCell> <div style={{ width: "4.8rem" }}>Visit Date</div></TableCell>

                  {/* <TableCell>Servic Order Created Date</TableCell> */}
                  {/* <TableCell>Accepeted Date</TableCell> */}
                  {/* <TableCell className='table-cell'>Customer Name</TableCell>
                  <TableCell className='table-cell'>Customer Address</TableCell> */}
                  <TableCell className='table-cell'>Customer Details</TableCell>

                  {/* <TableCell className='table-cell'>Brand</TableCell>
                  <TableCell className='table-cell'>Product</TableCell> */}
                  <TableCell className='table-cell'>Brand and Product</TableCell>

                  <TableCell>Symptom Code</TableCell>
                  <TableCell className='table-cell'>Description</TableCell>

                  <TableCell align='center' className='table-cell'>Repeat Call</TableCell>
                  <TableCell align='center' >
                    Job Status
                  </TableCell>
                  <TableCell align='center' >
                    Claim Status
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody style={{ verticalAlign: "top" }}>
                {
                  tableData && tableData?.map((item, index) =>
                    <>

                      <TableRow className='table-row' key={index} >
                        <TableCell sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 8px",
                          }
                        }}>{item?.serviceOrderId.charAt(0) === "X" ? item?.serviceOrderId :
                          <Link to={`/OrderDetails/${item.serviceOrderId}`}
                            style={{ color: " #02A0FC", textDecoration: "none" }}
                            onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
                            onMouseLeave={(e) => e.target.style.textDecoration = "none"}
                          >
                            <Typography sx={{ width: "6rem" }}> {item.serviceOrderId}
                            </Typography>
                          </Link>
                          }</TableCell>
                        {/* <TableCell>{item?.serviceOrderCreateDate}</TableCell> */}
                        {/* <TableCell>{item?.updateDateTime ? item?.updateDateTime : "Pending"}</TableCell> */}
                        <TableCell sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          },
                        }}>
                          <div>
                            {item?.appointmentDate + " "}
                          </div>

                          {item?.appointmentTimeSlot}

                        </TableCell>

                        {/* <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }}>{item?.consumer.firstName + " "}{item?.consumer.lastName}</TableCell> */}
                        <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }}> {item?.consumer.firstName + " "}{item?.consumer.lastName}<br />
                          {item?.consumer.address1 + ""}{item?.consumer.address2 + ", "}{item?.consumer.city + ","}{item?.consumer?.state + ","}{item?.consumer.postalCode}</TableCell>
                        {/* <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }}>{item?.product?.brandName}</TableCell> */}
                        <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }}>{item?.product?.brandName}<br />
                          {item?.product?.productName}</TableCell>
                        <TableCell sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }}>{item?.symptomCode}</TableCell>
                        <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }}>{item?.failureNote}</TableCell>

                        <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }} align='center'>{item?.repeatCall}</TableCell>
                        <TableCell sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }} align='center'>
                          {item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" ?
                            <>
                              <div style={{ display: "flex", flexDirection: "column" }}>

                                <Button
                                  disabled={onceClicked}
                                  variant='contained' color='success' sx={{ width: "5rem", marginBottom: "15px" }} onClick={() => handleAcceptRejectOrder("Accept", item.entityId)}>Accept</Button>

                                <Button
                                  disabled={onceClicked}

                                  variant='contained' color='error' sx={{ width: "5rem" }}
                                  // onClick={() => handleAcceptRejectOrder("Reject", item.entityId)}
                                  onClick={() => handleReject("Reject", item.entityId)}

                                >Reject</Button>
                              </div>

                            </>
                            : <>

                              {/* <ListItemText primaryTypographyProps={{ width: "4rem" }} primary={`Status :  `}></ListItemText> */}
                              <div style={{ display: "flex", justifyContent: 'center' }}>

                                <Typography
                                  sx={{
                                    bgcolor: item.status === 'Open' ? "#E1F1FE!important" : item.status === 'In Progress' ? "#F6E6E2!important" : item.status === 'Done' ? "#DCF8FF!important" : "" || item.subStatus === 'Open' ? "#E1F1FE!important" : item.subStatus === 'In Progress' ? "#F6E6E2!important" : item.subStatus === 'Done' ? "#DCF8FF!important" : "",
                                    color: item.status === 'Open' ? "#02A0FC" : item.status === 'In Progress' ? "#E56A54" : item.status === 'Done' ? "#009ABE" : "" || item.subStatus === 'Open' ? "#02A0FC" : item.subStatus === 'In Progress' ? "#E56A54" : item.subStatus === 'Done' ? "#009ABE" : "",

                                    padding: "10px 10px",
                                    borderRadius: "20px",
                                    fontSize: "12px",
                                    width: "85px",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}>
                                  <div>
                                    {item.status}

                                  </div>
                                  {item.subStatus}

                                </Typography>

                              </div>

                            </>


                            /* <ListItem>
                              <ListItemText primaryTypographyProps={{ width: "6.8rem" }} primary={`Sub-Status :  `}></ListItemText>
                              <ListItemText secondary={
                                <Typography
                                  sx={{
                                    bgcolor: item.subStatus === 'Open' ? "#E1F1FE!important" : item.subStatus === 'In Progress' ? "#F6E6E2!important" : item.subStatus === 'Done' ? "#DCF8FF!important" : "",
                                    color: item.subStatus === 'Open' ? "#02A0FC" : item.subStatus === 'In Progress' ? "#E56A54" : item.subStatus === 'Done' ? "#009ABE" : "",
      
                                    padding: "10px 10px",
                                    borderRadius: "20px",
                                    fontSize: "14px",
                                    width: "78px",
                                    textAlign: "center"
                                  }}>
                                  {item.subStatus}
                                </Typography>}>
      
                              </ListItemText>
      
                            // </ListItem> */
                            // </List>
                            // </>
                          }
                        </TableCell >

                        {
                          Array.isArray(item?.claims) && item?.claims ?
                            [item?.claims[0]].map((item) =>
                              <TableCell className='table-cell' sx={{
                                "&.MuiTableCell-root ": {
                                  padding: "10px 5px",
                                }
                              }} align='center'>
                                {item?.claimStatus && (
                                  <Typography
                                    sx={{
                                      bgcolor: "#F6E6E2!important",
                                      color: "#E56A54",

                                      padding: "10px 10px",
                                      borderRadius: "20px",
                                      fontSize: "12px",
                                      width: "85px",
                                      textAlign: "center",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center"
                                    }}>


                                    {item?.claimStatus}
                                  </Typography>
                                )}


                              </TableCell>
                            )
                            :
                            <TableCell className='table-cell' sx={{
                              "&.MuiTableCell-root ": {
                                padding: "10px 5px",
                              }
                            }} align='center'>

                            </TableCell>
                        }
                        {/* <TableCell className='table-cell' sx={{
                          "&.MuiTableCell-root ": {
                            padding: "10px 5px",
                          }
                        }} align='center'>
                          {item?.claims?.map((item) => item?.claimStatus)}
                        </TableCell> */}
                      </TableRow >
                    </>
                  )
                }
              </StyledTableBody>
            </Table>
          </TableContainer >

          {
            openRejectAuthDialog && (
              <RejectReasonAuthDialog
                handleOpenRejectAuthDialog={handleOpenRejectAuthDialog}
                handleCloseRejectAuthDialog={handleCloseRejectAuthDialog}
                handleAcceptRejectOrder={handleAcceptRejectOrder}
                rejectAuthReasons={rejectAuthReasons}
                value={value}
                orderId={orderId}

              />
            )
          }
          <Grid container style={{ marginTop: "0.9rem", justifyContent: "flex-end" }}>
            <Stack spacing={2}>
              <Pagination count={totalPages} page={page} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Stack>
          </Grid>

        </Grid >

      </Box>


      {/* Jobs Design for mobile responsive */}

      <Box
        component={Grid}
        item
        xs={12}
        display={{ xs: "block", sm: "none", md: "none" }}
        style={{}}
      >
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Jobs</Typography>
        </Grid>
        <Grid sx={{}} mt={2} ><JobsFilters refreshList={refreshList} onSearch={onSearch} disabledProp={false} setStoredQuery={setStoredQuery} setOffset={setOffset} offset={offset}></JobsFilters></Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: "1rem", marginTop: "1rem" }}>
          <Button sx={{ height: "1.6rem" }} variant='outlined' onClick={() => { handleExpandAll() }}>Expand All</Button>
        </Grid>

        <Grid xs={12} sx={{ marginLeft: '0.5rem' }}>
          {tableData && tableData?.map((item, index) => (
            <>
              {/* <Grid xs={12} key={index}> */}
              <CardContent className='jobs-card' sx={{ boxShadow: '0 0 0 1px #edebeb', marginTop: '1rem', padding: '0', borderRadius: '10px', paddingBottom: '0', '& .css-1on4i4i-MuiCardContent-root': { border: '1px solid red' } }} key={index}>
                <Grid sx={{ padding: '16px ' }}>
                  {/* get all jobs */}
                  <Grid xs={12} sx={{ display: 'flex' }} onClick={() => { handleOpenJobsection(index) }}>
                    <Grid xs={6}>

                      <Typography sx={{ width: "6rem" }}><b>{item?.serviceOrderId.charAt(0) === "X" ? item?.serviceOrderId : item.serviceOrderId}</b>
                      </Typography>
                    </Grid>
                    <Grid xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                      {
                        jobsOpen[index] && (
                          <KeyboardArrowUpOutlinedIcon onClick={() => { SetJobsOpen(false) }} sx={{ border: "1px solid black", borderRadius: "5px", height: "1rem", width: "1rem" }} />
                        )
                      }
                      {
                        !jobsOpen[index] && (
                          <KeyboardArrowDownOutlinedIcon onClick={() => { SetJobsOpen(false) }} sx={{ border: "1px solid black", borderRadius: "5px", height: "1rem", width: "1rem" }} />
                        )
                      }

                    </Grid>
                  </Grid>


                  {/* job data */}

                  <Grid>
                    <Grid>
                      <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "7rem" }}>Visit Date</Typography>
                      <Typography sx={{ fontSize: "13px", color: "#5C678F" }}>{item?.appointmentDate + "  "} {item?.appointmentTimeSlot}</Typography>
                    </Grid>

                    <Grid>
                      <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Customer Name</Typography>
                      <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.consumer.firstName + " "}{item?.consumer.lastName}</Typography>
                    </Grid>

                    {
                      jobsOpen[index] && (
                        <Grid>
                          <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Customer Address</Typography>
                          <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.consumer.address1 ? item?.consumer.address1 + "" : ""}{item?.consumer.address2 ? (" " + item?.consumer.address2 + ",") : ", "}{item?.consumer?.state + ","}{item?.consumer.city + ","}{item?.consumer.postalCode}
                          </Typography>
                        </Grid>
                      )
                    }


                  </Grid>

                  {
                    jobsOpen[index] && (
                      <Grid sx={{ backgroundColor: '#f5f6f7', padding: '10px 15px', marginTop: '1rem', borderRadius: '10px' }}>

                        <Grid>
                          <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Brand</Typography>
                          <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.product?.brandName}
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Product </Typography>
                          <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.product?.productName}
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>symptom Code</Typography>
                          <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.symptomCode}
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Description</Typography>
                          <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.failureNote}
                          </Typography>
                        </Grid>

                        <Grid>
                          <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Repeat Call</Typography>
                          <Typography sx={{ fontSize: "13px", color: "#5C678F" }} >{item?.repeatCall}
                          </Typography>
                        </Grid>

                      </Grid>
                    )
                  }




                  {/* status of Job */}
                  <div className='status-class' style={{ marginTop: '1rem' }} >
                    {item.status === "In Progress" && item.subStatus === "Awaiting Servicer Acceptance" ?
                      <>
                        <div style={{ display: "flex", marginBottom: '0.5rem', height: '3rem' }}>
                          <Button
                            disabled={onceClicked}
                            variant='contained' color='success' sx={{ width: "5rem", marginBottom: '0.7rem', marginLeft: '1rem' }} onClick={() => handleAcceptRejectOrder("Accept", item.entityId)}>Accept</Button>
                          <Button
                            disabled={onceClicked}
                            variant='contained' color='error' sx={{ width: "5rem", marginBottom: '0.7rem', marginLeft: '1rem' }}
                            // onClick={() => handleAcceptRejectOrder("Reject", item.entityId)}
                            onClick={() => handleReject("Reject", item.entityId)}
                          >Reject</Button>
                        </div>
                      </>
                      : <>
                        <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#233849", marginTop: '0.5rem', width: "auto" }}>Status</Typography>
                        <Typography
                          sx={{
                            bgcolor: item.status === 'Open' ? "#E1F1FE!important" : item.status === 'In Progress' ? "#F6E6E2!important" : item.status === 'Done' ? "#DCF8FF!important" : "" || item.subStatus === 'Open' ? "#E1F1FE!important" : item.subStatus === 'In Progress' ? "#F6E6E2!important" : item.subStatus === 'Done' ? "#DCF8FF!important" : "",
                            color: item.status === 'Open' ? "#02A0FC" : item.status === 'In Progress' ? "#E56A54" : item.status === 'Done' ? "#009ABE" : "" || item.subStatus === 'Open' ? "#02A0FC" : item.subStatus === 'In Progress' ? "#E56A54" : item.subStatus === 'Done' ? "#009ABE" : "",
                            padding: "10px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            width: "80%",
                            textAlign: "center",
                            display: "flex",
                          }}>
                          {item.status} / {item.subStatus}
                        </Typography>
                        <Grid>
                          {item?.serviceOrderId.charAt(0) === "X" ? "" :
                            <Link to={`/OrderDetails/${item.serviceOrderId}`}
                              style={{ color: " #02A0FC", textDecoration: "none" }}
                              onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
                              onMouseLeave={(e) => e.target.style.textDecoration = "none"}
                            >
                              <Button variant="contained" style={{ display: 'flex', justifyContent: 'space-between', marginTop: "1rem", height: "1.6rem" }}>
                                View Details <ChevronRightIcon />
                              </Button>
                            </Link>
                          }
                        </Grid>
                      </>
                    }
                  </div>
                </Grid>
              </CardContent>
              {/* </Grid>  */}

            </>
          ))}
        </Grid>

      </Box>


    </>
  )
}

export default Jobs