import { Button, Grid, Typography } from "@mui/material"
import ServiceAvengerIcon from "../../assets/SeviceAvengerIcon.svg"
import "./ServicerNavBar.scss"
import { MenuItems } from "../../components/ServicerNavBar/ServicerNavBarMenuItems"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Image } from "@mui/icons-material"
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux"

const ServicerNavBar = ({ setHamburgerMenu, hamburgerMenu }) => {
  const menuItems=MenuItems()
  const navigate = useNavigate()
  const location = useLocation();
  const [clickedButton, setClickedButton] = useState(location.state?.activeIndex);
  const [keyCLockToken, setkeyCloakLoginToken] = useState(localStorage?.getItem("keyCloakLoginToken"))
  const getFileClaimWithoutJobFromRedux = useSelector((state) => {
    return Number(state?.Login?.fileClaimWithoutJob)
  })
  const getEntitlementFileUploadFromRedux = useSelector((state) => {
    return Number(state?.Login?.entitlementFileUpload)
  })
  const getPostDispatchEntitlementFromRedux = useSelector((state) => {
    return Number(state?.Login?.postDispatchEntitlement)
  })

  useEffect(() => {
    menuItems?.map((item) => {
      if (item.path == location.pathname) {
        setClickedButton(item.key)
      }
    })
  }, [location])

  const handleClose = () => {
    setHamburgerMenu(false)
  }

  const handleOnClick = (key, path, url) => {
    if (url) {
      window.open(`${process.env.REACT_APP_CLAIMS_URL}/dashboard?access_token=${keyCLockToken}`)
    }
    setClickedButton(key);
    if (path) {
      navigate(path)
    }
    handleClose()
  }

  return (
    <>
      <Grid xs={12} spacing={0} className="main-servicerNavBar" style={{ position: "fixed", boxSizing: 'border-box', minHeight: "100vh" }} >
        <Grid xs={2} container spacing={0} className="main-contianer-servicerNavBar" style={{ position: "fixed", top: "0", left: "0", maxHeight: "2vw", width: "80%" }}>
          <Grid className="close_icon" style={{ display: "flex", justifyContent: "flex-end", padding: "2vw 3vw", minWidth: "100%" }}>
            <Grid style={{ alignSelf: "flex-end", alignContent: "flex-end" }} >
              <CloseIcon style={{ height: "4vw", width: "4vw" }} onClick={() => { handleClose() }} />
            </Grid>
          </Grid>
          <Grid xs={12} className="main-container-avatar" style={{ display: "flex", justifyContent: "center" }}>
            <div className="image-container" >
              <img src={ServiceAvengerIcon} alt="icon" className="serviceAvenger-icon-image" />
            </div>
          </Grid>
          {/* {
            menuItems.map((item, key) => (
              <Grid item xs={12} className="servicer-name" key={item.key} >
                {
                  (
                    (item?.key === '4' || item?.key === '5') ? getFileClaimWithoutJobFromRedux : item?.show
                  ) ? (
                    <Button className="servicer-name-button" variant={clickedButton && clickedButton === item.key ? "contained" : ""}
                      disableElevation
                      disableFocusRipple
                      disableTouchRipple
                      sx={{
                        "&.MuiButtonBase-root": {
                          padding: "8px 2px 8px 5px "
                        },
                        // border: "1px solid red",
                        width: "15vw",
                        wordBreak: "break-word",
                        "& .MuiTypography-root ": {
                          // backgroundColor: "#F3F6FD",
                          fontSize: "13px",

                        },
                        "&.MuiButton-root": {
                          display: "flex",
                          justifyContent: "flex-start",
                          fontSize: "300px",
                        },
                        '@media  (max-width:800px)': {
                          width: "16px",
                          fontSize: "14px",
                          padding: "4px 12px",
                          // marginLeft:"1rem",
                          // marginRight:"1rem",
                          // border:"1px solid red" 
                        },
                        '@media (max-width:1023)': {
                          fontSize: "9.7px",
                          padding: "0 2px",
                          border: "1px solid red"
                        },
                        '@media (min-width: 1024px) and (max-width: 1282px)': {
                          fontSize: "14px",
                          padding: "5px 5px",
                          // border:"1px solid red"
                        },

                      }}
                      onClick={() => handleOnClick(item.key, item?.path, item?.isUrl)}>{clickedButton && clickedButton === item.key ? <img src={item.Icon[1]} alt="icon" /> : <img src={item.Icon[0]} alt="icon" />}
                      <span className="servicer-navbar-title" style={{ marginLeft: "5px" }}></span><Typography sx={{ textTransform: "none" }} className="servicer-navbar-title" >{item.title}</Typography>
                    </Button>

                  ) : null
                }
              </Grid>
            ))
          } */}
          {
  menuItems?.map((item) => {
    const shouldShowItem = (item?.key === '4' && getFileClaimWithoutJobFromRedux && getPostDispatchEntitlementFromRedux) ||
                           (item?.key === '5' && getFileClaimWithoutJobFromRedux && getEntitlementFileUploadFromRedux) ||
                           item?.show;
    
    if (!shouldShowItem) return null;

    return (
      <Grid item xs={12} className="servicer-name" key={item.key}>
        <Button
          className="servicer-name-button"
          variant={clickedButton && clickedButton === item.key ? "contained" : ""}
          disableElevation
          disableFocusRipple
          disableTouchRipple
          sx={{
            "&.MuiButtonBase-root": { padding: "8px 2px 8px 5px" },
            width: "15vw",
            wordBreak: "break-word",
            "& .MuiTypography-root": { fontSize: "13px" },
            "&.MuiButton-root": { display: "flex", justifyContent: "flex-start" },
            '@media (max-width:800px)': { width: "16px", fontSize: "14px", padding: "4px 12px" },
            '@media (max-width:1023px)': { fontSize: "9.7px", padding: "0 2px"},
            '@media (min-width:1024px) and (max-width:1282px)': { fontSize: "14px", padding: "5px 5px" },
          }}
          onClick={() => handleOnClick(item.key, item?.path, item?.isUrl)}
        >
          {clickedButton && clickedButton === item.key
            ? <img src={item.Icon[1]} alt="icon" />
            : <img src={item.Icon[0]} alt="icon" />}
          <span className="servicer-navbar-title" style={{ marginLeft: "5px" }}></span>
          <Typography sx={{ textTransform: "none" }} className="servicer-navbar-title">{item.title}</Typography>
        </Button>
      </Grid>
    );
  })
}

        </Grid>
      </Grid>
    </>
  )
}
export default ServicerNavBar